import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { registerParticipant } from '../../../services/api';
import './styles/noviceForm.css';

const NoviceForm = ({ language, meetId }) => {

    const isFemale = useRef();
    const isMale = useRef();
    const noviceRegistration = useRef();
    const division = useRef();
    const type = useRef();
    const menFilter = useRef();
    const womenFilter = useRef();
    const [gender, setGender] = useState("M");
    const [labels] = useState({
        "first": {
            "french": "Prénom",
            "english": "First name"
        },
        "last": {
            "french": "Nom",
            "english": "Last name"
        },
        "gender": {
            "french": "Genre",
            "english": "Gender"
        },
        "man": {
            "french": "Homme",
            "english": "Man"
        },
        "woman": {
            "french": "Femme",
            "english": "Woman"
        },
        "email": {
            "french": "Courriel",
            "english": "Email"
        },
        "pl": {
            "french": "Dynamophilie",
            "english": "Powerlifting"
        },
        "cl": {
            "french": "Classique",
            "english": "Classic"
        },
        "eq": {
            "french": "Équipé",
            "english": "Equipped"
        },
        "wc": {
            "french": "Classe de poids",
            "english": "Weight class"
        },
        "final": {
            "french": "Finaliser l'inscription",
            "english": "Finalize registration"
        }
    });

    const handleMaleFemale = () => {
        if(isMale.current.className === "selected") {
            isFemale.current.className = "selected";
            isMale.current.className = "";

            womenFilter.current.className = "filter";
            menFilter.current.className = "filter invisible";
            setGender("F");
        } else {
            isFemale.current.className = "";
            isMale.current.className = "selected";

            womenFilter.current.className = "filter invisible";
            menFilter.current.className = "filter";
            setGender("M");
        };
    };

    const handleSubmit = async () => {
    
        const participantData = {
          isNovice: true,
          lastName: document.getElementById('lname').value,
          firstName: document.getElementById('fname').value,
          ageClass: "Open",
          possibleAgeClasses: ["Open"],
          gender: gender,
          type: type.current.value,
          division: division.current.value,
          weightClass: (gender === "M" ? menFilter.current.value : womenFilter.current.value),
          email: document.getElementById('email').value,
          status: ""
        };
    
        try {
            console.log(meetId);
            console.log(participantData);
          await registerParticipant(meetId, participantData);
        } catch (error) {
          console.log('ERROR');
        }
      };

    const register = async () => {
        if(document.getElementById('lname').value === "") {
            alert(labels['lname'][language["language"]]);
        } else if(document.getElementById('fname').value === "") {
            alert(labels['fname'][language["language"]]);
        } else if(!document.getElementById('email').value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            alert(labels['email-er'][language["language"]]);
        } else {
            await handleSubmit();
            window.location.href = "/meets/" + meetId + "/confirmation/";
        }
    };

    return (
        <div className="novice-registration-container" ref={noviceRegistration}> 
            <div className="novice-info-container details-container">
                <div className="details">
                    <p className="detail-label">{labels['first'][language["language"]]}</p>
                    <input type="text" id="fname" name="fname" />
                </div>
                <div className="details">
                    <p className="detail-label">{labels['last'][language["language"]]}</p>
                    <input type="text" id="lname" name="lname" />
                </div>
                <div className="details sex-box">
                    <p className="detail-label">{labels['gender'][language["language"]]}</p>
                    <div className="sex-toggle">
                        <button onClick={() => handleMaleFemale()} className="selected" ref={isMale}>{labels['man'][language["language"]]}</button>
                        <button onClick={() => handleMaleFemale()} ref={isFemale}>{labels['woman'][language["language"]]}</button>
                    </div>
                </div>
                <div className="details">
                    <p className="detail-label">{labels['email'][language["language"]]}</p>
                    <input type="email" id="email" name="email" />
                </div>
            </div>

            <div className="line"></div>

            <div className="novice-athlete-container details-container">
                <div className="details">
                    <p className="detail-label">Type</p>
                    <select ref={type} className="filter">
                        <option value="Dynamophilie">{labels['pl'][language["language"]]}</option>
                        <option value="Bench Press">Bench Press</option>
                    </select>
                </div>

                <div className="details">
                    <p className="detail-label">Division</p>
                    <select ref={division} className="filter">
                        <option value="Classique">{labels['cl'][language["language"]]}</option>
                        <option value="Équipé">{labels['eq'][language["language"]]}</option>
                    </select>
                </div>

                <div className="details">
                    <p className="wc-novice">{labels['wc'][language["language"]]}</p>
                    <div className="select-container">
                        <select ref={menFilter} className="filter">
                            <option value="-53 kg">-53 kg</option>
                            <option value="-59 kg">-59 kg</option>
                            <option value="-66 kg">-66 kg</option>
                            <option value="-74 kg">-74 kg</option>
                            <option value="-83 kg">-83 kg</option>
                            <option value="-93 kg">-93 kg</option>
                            <option value="-105 kg">-105 kg</option>
                            <option value="-120 kg">-120 kg</option>
                            <option value="+120 kg">+120 kg</option>
                        </select>
                        <select ref={womenFilter} className="filter invisible">
                            <option value="-43 kg">-43 kg</option>
                            <option value="-47 kg">-47 kg</option>
                            <option value="-52 kg">-52 kg</option>
                            <option value="-57 kg">-57 kg</option>
                            <option value="-63 kg">-63 kg</option>
                            <option value="-69 kg">-69 kg</option>
                            <option value="-76 kg">-76 kg</option>
                            <option value="-84 kg">-84 kg</option>
                            <option value="+84 kg">+84 kg</option>
                        </select>
                    </div>
                </div>

                <Link to={"/meets/" + meetId + "/confirmation/"} onClick={() => register()} className="final">{labels['final'][language["language"]]}</Link>
            </div>
        </div>
    );
};

export default NoviceForm;