import './membership.css';

import {useLayoutEffect, useRef, useState} from 'react';

import newRegistration1 from './assets/registration/1-new.png';
import renewRegistration1 from './assets/registration/1-renew.png';
import step2 from './assets/registration/2.png';
import step3 from './assets/registration/3.png';
import step4 from './assets/registration/4.png';
import step5 from './assets/registration/5.png';
import step6 from './assets/registration/6.png';
import step7 from './assets/registration/7.png';

import cces1 from './assets/registration/cces/1.png';
import cces1o from './assets/registration/cces/1o.png';
import cces2 from './assets/registration/cces/2.png';
import cces3 from './assets/registration/cces/3.png';

const Membership = ({language}) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const inscription = useRef();
    const inscriptionButton = useRef();

    const cces = useRef();
    const ccesButton = useRef();

    const renewCpu = useRef();
    const renewCpuButton = useRef();

    const renewCces = useRef();
    const renewCcesButton = useRef();

    const onClick = (button, div) => {
        if(div.current.className === "content-wrapper-on") {
            button.current.innerHTML = "&plus;";
            div.current.className = "content-wrapper-off";
        } else {
            button.current.innerHTML = "&minus;";
            div.current.className = "content-wrapper-on";;
        }
    };

    const [labels] = useState({
        "content": {
            "french": [
                <div key={1} className="membership-container">
                    <h1>DEVENIR MEMBRE</h1>
        
                    <h2>NOUVEAU MEMBRE</h2>
        
                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(inscriptionButton, inscription)}>
                            <h3>Inscription à la CPU</h3>
                            <button ref={inscriptionButton}>&#43;</button>
                        </div>
        
                        <div ref={inscription} className="content-wrapper-off">
                            <ol>
                                <li>Cliquer sur ce <a target="_blank" rel="noreferrer" href="https://secure.esportsdesk.com/login.cfm?clientID=5894&leagueID=23917">lien</a></li>
                                <li>Cliquer "Sign Up" afin de créer votre compte. Vous devez inscrire votre prénom, nom et votre adresse courriel et appuyer sur "Sign Up". <br/><br/>
                                    <img src={newRegistration1} alt=""/>
                                </li>
                                <li>Une fois connecté, cliquer dans le menu de gauche sur "Memberships". <br/><br/> <img src={step2} alt=""/></li>
                                <li>Ensuite, vous devez choisir la carte selon votre âge. Les prix indiqués sont seulement la partie qui revient à la FQD. 45$ pour la CPU seront automatiquement ajoutés. <br/><br/> <img src={step3} alt=""/></li>
                                <li>La prochaine étape consiste à répondre à la question "Avez-vous déjà été suspendu de la compétition?". Si vous répondez oui, vous devez inscrire quelques explications. Ensuite, vous devez lire et approuvez la renonciation. Si vous êtes d'accord, vous devez coche la case et appuyez sur le bouton "Continue". <br/><br/>  <img src={step4} alt=""/></li>
                                <li>La prochaine étape est le paiement. Vous devez appuyer sur le bouton "Check Out" et vous devez inscrire vos informations personnelles et les informations de votre carte de crédit. <br/><br/> <img src={step5} alt=""/></li>
                                <li>Une fois votre paiement complété, vous devez vous déconnecter et vous reconnecter pour avoir accès à l'impression de votre carte. Ensuite, vous devez cliquer de nouveau dans le menu de gauche sur "Memberships" et vous pourrez maintenant imprimer votre carte. <br/><br/> <img src={step6} alt=""/></li>
                                <li>Votre numéro de membre est le numéro en dessous de "ID NUMBER" <br/><br/> <img src={step7} alt=""/></li>
                            </ol>
                        </div>
                    </div>
        
                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(ccesButton, cces)}>
                            <h3>CCES</h3>
                            <button ref={ccesButton}>&#43;</button>
                        </div>
        
                        <div ref={cces} className="content-wrapper-off">
                            <ol>
                                <li>Pour le programme de formation en ligne obligatoire du CCES, consultez ce <a target="_blank" rel="noreferrer" href="http://education.cces.ca/?keyname=CpuQC2016">lien</a>.</li>
                                <li><p>Veuillez remplir vos informations puis cliquer sur "Sign Up".</p> <br/><br/>
                                    <img src={cces1} alt=""/>
                                </li>
                                <li><p>Sélectionnez ensuite le cours Sport pur : L’ABC du sport sain pour débuter la formation en ligne.</p> <br/><br/>
                                    <img src={cces2} alt=""/></li>
                                <li><p>Il est possible que vous ayez à mettre à jour votre profil avant de débuter la formation. Veuillez prendre le temps de remplir correctement les informations demandées, celles-ci sont pertinentes à la validation de votre réussite du programme.</p> <br/><br/>
                                    <img src={cces3} alt=""/></li>
                                <li><p>Il ne vous reste plus qu’à écouter les différentes capsules puis à répondre correctement aux questions pour réussir votre programme de formation en prévention du dopage.</p></li>
                            </ol>
                        </div>
                    </div>
        
                    <h2>RENOUVELLEMENT</h2>
        
                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(renewCpuButton, renewCpu)}>
                            <h3>Renouvellement de la carte du CPU</h3>
                            <button ref={renewCpuButton}>&#43;</button>
                        </div>
        
                        <div ref={renewCpu} className="content-wrapper-off">
                            <ol>
                                <li>Cliquer sur ce <a target="_blank" rel="noreferrer" href="https://secure.esportsdesk.com/login.cfm?clientID=5894&leagueID=23917">lien</a></li>
                                <li>Veuillez cliquer "Sign In" puis inscrire votre courriel et mot de passe.<br/><br/>
                                    <img src={renewRegistration1} alt=""/>
                                </li>
                                <li>Une fois connecté, cliquer dans le menu de gauche sur "Memberships". <br/><br/> <img src={step2} alt=""/></li>
                                <li>Ensuite, vous devez choisir la carte selon votre âge. Les prix indiqués sont seulement la partie qui revient à la FQD. 45$ pour la CPU seront automatiquement ajoutés. <br/><br/> <img src={step3} alt=""/></li>
                                <li>La prochaine étape consiste à répondre à la question "Avez-vous déjà été suspendu de la compétition?". Si vous répondez oui, vous devez inscrire quelques explications. Ensuite, vous devez lire et approuvez la renonciation. Si vous êtes d'accord, vous devez coche la case et appuyez sur le bouton "Continue". <br/><br/>  <img src={step4} alt=""/></li>
                                <li>La prochaine étape est le paiement. Vous devez appuyer sur le bouton "Check Out" et vous devez inscrire vos informations personnelles et les informations de votre carte de crédit. <br/><br/> <img src={step5} alt=""/></li>
                                <li>Une fois votre paiement complété, vous devez vous déconnecter et vous reconnecter pour avoir accès à l'impression de votre carte. Ensuite, vous devez cliquer de nouveau dans le menu de gauche sur "Memberships" et vous pourrez maintenant imprimer votre carte. <br/><br/> <img src={step6} alt=""/></li>
                                <li>Votre numéro de membre est le numéro en dessous de "ID NUMBER" <br/><br/> <img src={step7} alt=""/></li>
                            </ol>
                        </div>
                    </div>
        
                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(renewCcesButton, renewCces)}>
                            <h3>Renouvellement du CCES</h3>
                            <button ref={renewCcesButton}>&#43;</button>
                        </div>
        
                        <div ref={renewCces} className="content-wrapper-off">
                            <ol>
                                    <li>Pour le programme de formation en ligne obligatoire du CCES, consultez ce <a target="_blank" rel="noreferrer" href="http://education.cces.ca/?keyname=CpuQC2016">lien</a>.</li>
                                    <li><p>Cliquez sur "Login" (en haut à droite) pour vous connecter à votre compte.</p> <br/><br/>
                                        <img src={cces1o} alt=""/>
                                    </li>
                                    <li><p>Sélectionnez ensuite le cours Sport pur : L’ABC du sport sain pour débuter la formation en ligne.</p> <br/><br/>
                                        <img src={cces2} alt=""/></li>
                                    <li><p>Il est possible que vous ayez à mettre à jour votre profil avant de débuter la formation. Veuillez prendre le temps de remplir correctement les informations demandées, celles-ci sont pertinentes à la validation de votre réussite du programme.</p> <br/><br/>
                                        <img src={cces3} alt=""/></li>
                                    <li><p>Il ne vous reste plus qu’à écouter les différentes capsules puis à répondre correctement aux questions pour réussir votre programme de formation en prévention du dopage.</p></li>
                            </ol>
                        </div>
                    </div>
                </div>], 
            "english": [
                <div key={2} className="membership-container">
                <h1>BECOME A MEMBER</h1>
    
                <h2>NEW MEMBER</h2>
    
                <div className="bubble-container">
                    <div className="top-header" onClick={() => onClick(inscriptionButton, inscription)}>
                        <h3>CPU Registration</h3>
                        <button ref={inscriptionButton}>&#43;</button>
                    </div>
    
                    <div ref={inscription} className="content-wrapper-off">
                        <ol>
                            <li>Click on this <a target="_blank" rel="noreferrer" href="https://secure.esportsdesk.com/login.cfm?clientID=5894&leagueID=23917">link</a></li>
                            <li>Click "Sign Up" to create your account. You must enter your first name, last name and your email address and press "Sign Up".<br/><br/>
                                <img src={newRegistration1} alt=""/>
                            </li>
                            <li>Once logged in, click in the left menu on "Memberships". <br/><br/> <img src={step2} alt=""/></li>
                            <li>Then you have to choose the card according to your age. The prices indicated are only the part which returns to the QPF. The $45 for the CPU will be automatically added. <br/><br/> <img src={step3} alt=""/></li>
                            <li>The next step is to answer the question "Have you ever been suspended from competition?". If you answer yes, you must provide some explanations. Then you must read and approve the waiver. If you agree, you should check the box and press the "Continue" button. <br/><br/>  <img src={step4} alt=""/></li>
                            <li>The next step is the payment. You must press the "Check Out" button and you must enter your personal information and your credit card information. <br/><br/> <img src={step5} alt=""/></li>
                            <li>Once your payment is complete, you must disconnect and reconnect to have access to printing your card. Then you have to click again in the left menu on "Memberships" and you can now print your card. <br/><br/> <img src={step6} alt=""/></li>
                            <li>Your membership number is the number below "ID NUMBER"<br/><br/> <img src={step7} alt=""/></li>
                        </ol>
                    </div>
                </div>
    
                <div className="bubble-container">
                    <div className="top-header" onClick={() => onClick(ccesButton, cces)}>
                        <h3>CCES</h3>
                        <button ref={ccesButton}>&#43;</button>
                    </div>
    
                    <div ref={cces} className="content-wrapper-off">
                        <ol>
                            <li>For the mandatory CCES online training program, see this <a target="_blank" rel="noreferrer" href="http://education.cces.ca/?keyname=CpuQC2016">link</a>.</li>
                            <li><p>Please fill in your information then click on "Sign Up".</p> <br/><br/>
                                <img src={cces1} alt=""/>
                            </li>
                            <li><p>Then select the True Sport: The ABCs of Healthy Sport course to begin the online training.</p> <br/><br/>
                                <img src={cces2} alt=""/></li>
                            <li><p>You may need to update your profile before starting the training. Please take the time to correctly fill in the requested information, it is relevant to the validation of your successful completion of the program.</p> <br/><br/>
                                <img src={cces3} alt=""/></li>
                            <li><p>All you have to do is listen to the various capsules and then answer the questions correctly to make your doping prevention training program a success.</p></li>
                        </ol>
                    </div>
                </div>
    
                <h2>RENEWAL</h2>
    
                <div className="bubble-container">
                    <div className="top-header" onClick={() => onClick(renewCpuButton, renewCpu)}>
                        <h3>CPU Membership Renewal</h3>
                        <button ref={renewCpuButton}>&#43;</button>
                    </div>
    
                    <div ref={renewCpu} className="content-wrapper-off">
                        <ol>
                            <li>Click on this <a target="_blank" rel="noreferrer" href="https://secure.esportsdesk.com/login.cfm?clientID=5894&leagueID=23917">link</a></li>
                            <li>Please click "Sign In" then enter your email and password.<br/><br/>
                                <img src={renewRegistration1} alt=""/>
                            </li>
                            <li>Once logged in, click in the left menu on "Memberships". <br/><br/> <img src={step2} alt=""/></li>
                            <li>Then you have to choose the card according to your age. The prices indicated are only the part which returns to the QPF. The $ 45 for the CPU will be automatically added. <br/><br/> <img src={step3} alt=""/></li>
                            <li>The next step is to answer the question “Have you ever been suspended from competition?”. If you answer yes, you must provide some explanations. Then you must read and approve the waiver. If you agree, you must check the box and press the "Continue" button.<br/><br/>  <img src={step4} alt=""/></li>
                            <li>The next step is the payment. You must press the "Check Out" button and you must enter your personal information and your credit card information. <br/><br/> <img src={step5} alt=""/></li>
                            <li>Once your payment is complete, you must disconnect and reconnect to have access to printing your card. Then you have to click again in the left menu on "Memberships" and you can now print your card. <br/><br/> <img src={step6} alt=""/></li>
                            <li>Your membership number is the number below "ID NUMBER". <br/><br/> <img src={step7} alt=""/></li>
                        </ol>
                    </div>
                </div>
    
                <div className="bubble-container">
                    <div className="top-header" onClick={() => onClick(renewCcesButton, renewCces)}>
                        <h3>CCES Renewal</h3>
                        <button ref={renewCcesButton}>&#43;</button>
                    </div>
    
                    <div ref={renewCces} className="content-wrapper-off">
                        <ol>
                                <li>For the mandatory CCES online training program, see this <a target="_blank" rel="noreferrer" href="http://education.cces.ca/?keyname=CpuQC2016">link</a>.</li>
                                <li><p>Click on "Login" (top right) to log into your account.</p> <br/><br/>
                                    <img src={cces1o} alt=""/>
                                </li>
                                <li><p>Then select the True Sport: The ABCs of Healthy Sport course to begin the online training.</p> <br/><br/>
                                    <img src={cces2} alt=""/></li>
                                <li><p>You may need to update your profile before starting the training. Please take the time to correctly fill in the requested information, it is relevant to the validation of your successful completion of the program.</p> <br/><br/>
                                    <img src={cces3} alt=""/></li>
                                <li><p>All you have to do is listen to the various capsules and then answer the questions correctly to make your doping prevention training program a success.</p></li>
                        </ol>
                    </div>
                </div>
            </div>
            ], 
        }
    })

    return (
    <>
        {labels['content'][language["language"]]}
    </>
    );
}

export default Membership
