import './anti-doping.css';
import {useState} from 'react'
import {Link} from 'react-router-dom'

const AntiDoping = ({language}) => {

    const [labels] = useState({
        "anti-doping": {"french": "ANTIDOPAGE", "english": "ANTI-DOPING"},
        "organizations": {"french": "Organismes", "english": "Organizations"},
        "information": {"french": "Informations", "english": "Information"},
        "cces": {"french": "Centre canadien pour l'éthique dans le sport (CCES)", "english": "Canadian Centre for Ethics in Sport (CCES)"},
        "wada": {"french": "Agence Mondiale Antidopage (AMA)", "english": "World Anti-Doping Agency (WADA)"},
        "list": {"french": "Télécharger la liste des substances interdites (AMA)", "english": "Download the list of prohibited substances (WADA)"},
        "medics": {"french": "Verifier vos médicaments (DRO GLOBAL)", "english": "Check your medications (GLOBAL DRO)"},
        "medics-questions": {"french": "Questions à propos de vos médicaments (CCES)", "english": "Questions about your medications (CCES)"},
        "authorizations": {"french": "Autorisations d’usage à des fins thérapeutiques (CCES)", "english": "Therapeutic use authorizations (CCES)"},
        "suspensions": {"french": "Athlètes suspendus", "english": "Suspended athletes"},
        "name": {"french": "Nom", "english": "Name"},
        "date-suspension": {"french": "Date de la suspension", "english": "Date of suspension"},
        "date-marcil": {"french": "3 octobre 2021 au 3 octobre 2023", "english": "October 3, 2021 to October 3, 2023"},
        "date-maltais": {"french": "11 septembre 2021 au 1 septembre 2025", "english": "September 11, 2021 to September 11, 2025"},
        "lgd": {"french": "Présence et usage : SARM LGD-4033", "english": "Presence and use: SARM LGD-4033"},
        "taximofen": {"french": "Présence et usage : tamoxifène", "english": "Presence and use: tamoxifen"}
    })

    return (
        <div className="anti-doping-container">
            <h1>{labels['anti-doping'][language["language"]]}</h1>

            <div className="bubble-container">
                <div className="top-header">
                    <h3>{labels['organizations'][language["language"]]}</h3>
                </div>

                <div className="content-wrapper-on">
                <ul>
                    <li><a target="_blank" rel="noreferrer" href="https://cces.ca/fr">{labels['cces'][language["language"]]}</a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://www.wada-ama.org/fr/">{labels['wada'][language["language"]]})</a></li>
                </ul>
                </div>
            </div>

            <div className="bubble-container">
                <div className="top-header">
                    <h3>{labels['information'][language["language"]]}</h3>
                </div>

                <div className="content-wrapper-on">
                <ul>
                    <li><a target="_blank" rel="noreferrer" href="https://www.wada-ama.org/sites/default/files/wada_2020_french_prohibited_list.pdf">{labels['list'][language["language"]]}</a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://www.globaldro.com/CA/search?changelang=fr-ca">{labels['medics'][language["language"]]}</a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://cces.ca/fr/verifiez-vos-medicaments">{labels['medics-questions'][language["language"]]}</a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://cces.ca/fr/autorisation-dusage-des-fins-therapeutiques">{labels['authorizations'][language["language"]]}</a></li>
                </ul>
                </div>
            </div>

            <h2>{labels['suspensions'][language["language"]]}</h2>
            <div className="table-container">
                <div className="table-wrapper">
                    <table>
                        <tbody>
                            <tr>
                                <th>{labels['name'][language["language"]]}</th>
                                <th>Infraction</th>
                                <th>{labels['date-suspension'][language["language"]]}</th>
                            </tr>
                            <tr>
                                <td><Link to="/athletes/1139">Darren Gagnon-Maltais</Link></td>
                                <td>{labels['lgd'][language["language"]]}</td>
                                <td>{labels['date-maltais'][language["language"]]}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AntiDoping
