import Header from './common/Header';
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import './main.css';

import catherinel from './assets/backgrounds/landscape/catherine-gagne.jpg'
import catherinep from './assets/backgrounds/portrait/catherine-gagne.jpg'

import amel from './assets/backgrounds/landscape/amelie-picher-plante.jpg'
import amep from './assets/backgrounds/portrait/amelie-picher-plante.jpg'

import haroldl from './assets/backgrounds/landscape/harold-veilleux.jpg'
import haroldp from './assets/backgrounds/portrait/harold-veilleux.jpg'

import hugol from './assets/backgrounds/landscape/hugo-bouthiette.jpg'
import hugop from './assets/backgrounds/portrait/hugo-bouthiette.jpg'

import judithl from './assets/backgrounds/landscape/judith-lavigueur.jpg'
import judithp from './assets/backgrounds/portrait/judith-lavigueur.jpg'

import kaylal from './assets/backgrounds/landscape/kayla-overchuk.jpg'
import kaylap from './assets/backgrounds/portrait/kayla-overchuk.jpg'

import melinal from './assets/backgrounds/landscape/melina-servant.jpg'
import melinap from './assets/backgrounds/portrait/melina-servant.jpg'

import tuanl from './assets/backgrounds/landscape/tran-tuan.jpg'
import tuanp from './assets/backgrounds/portrait/tran-tuan.jpg'

const Main = ({language, setLanguage, title, date, content}) => {

    const [labels] = useState({"featured-athlete": {"french": "ATHLÈTE EN VEDETTE", "english": "FEATURED ATHLETE"},
                                            "description": {"french": "CHAMPIONNE CANADIENNE JUNIOR", "english": "CANADIAN JUNIOR CHAMPION"},
                                            "news": {"french": "NOUVELLES", "english": "NEWS"},
                                            "read-more": {"french": "Lire la suite", "english": "Read more"},
                                            "coming-competitions": {"french": "COMPÉTITIONS À VENIR", "english": "UPCOMING COMPETITIONS"},
                                            "registration": {"french": "INSCRIPTION", "english": "REGISTRATION"},
                                            "no-competition-title": {"french": "Aucune compétition à afficher pour l'instant", "english": "No competition to display for the moment",},
                                            "date": {"french": "2 AVRIL 2022", "english": "APRIL 2 2022",},
                                            "kayla": {
                                                "french": "Vice-championne mondiale 2024 (Junior -76 kg) et Championne nationale 2024 (Open -76 kg)", 
                                                "english": "2024 World Vice-Champion (Junior -76 kg) and 2024 National Champion (Open -76 kg)"},
                                            "catherine": {
                                                "french": "Championne nationale 2024 (Open -52 kg)", 
                                                "english": "2024 National Champion (Open -52 kg)"},
                                            "amelie": {
                                                "french": "Championne nationale 2024 (Open -57 kg)", 
                                                "english": "2024 National Champion (Open -57 kg)"},
                                            "melina": {
                                                "french": "Championne nationale 2024 (Open -69 kg)", 
                                                "english": "2024 National Champion (Open -69 kg)"},
                                            "hugo": {
                                                "french": "Champion NAPF 2024 (Open -83 kg)", 
                                                "english": "2024 NAPF Champion (Open -83 kg)"},
                                            "harold": {
                                                "french": "Champion NAPF et mondial 2024 (Master 3 -120 kg)", 
                                                "english": "2024 NAPF and World Champion (Master 3 -120 kg)"},
                                            "tuan": {
                                                "french": "Détenteur du record mondial au deadlift (Junior -66 kg)",
                                                "english": "World record holder in the deadlift (Junior -66 kg"},
                                            "judith": {
                                                "french": "Championne du Commonwealth 2024 (Junior -63 kg)", 
                                                "english": "2024 Commonwealth Champion (Junior -63 kg)"}
                                        });

    const [meets, setMeets] = useState([]);
    const [currentMeetIndex, setCurrentMeetIndex] = useState(0);
    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets?status=active', {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                myJson.sort((a,b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
                setMeets(myJson);
            });
    // eslint-disable-next-line
    }, []);

    const [backgrounds] = useState([
        {
            name: "AMÉLIE PICHER-PLANTE",
            description: "amelie",
            landscape: [amel],
            portrait: [amep],
            id: 782
        },
        {
            name: "CATHERINE GAGNÉ",
            description: "catherine",
            landscape: [catherinel],
            portrait: [catherinep],
            id: 1178
        },
        {
            name: "HAROLD VEILLEUX",
            description: "harold",
            landscape: [haroldl],
            portrait: [haroldp],
            id: 386
        },
        {
            name: "HUGO BOUTHIETTE",
            description: "hugo",
            landscape: [hugol],
            portrait: [hugop],
            id: 1016
        },
        {
            name: "JUDITH LAVIGUEUR",
            description: "judith",
            landscape: [judithl],
            portrait: [judithp],
            id: 1737
        },
        {
            name: "KAYLA OVERCHUK",
            description: "kayla",
            landscape: [kaylal],
            portrait: [kaylap],
            id: 1322
        },
        {
            name: "MÉLINA SERVANT",
            description: "melina",
            landscape: [melinal],
            portrait: [melinap],
            id: 1050
        },
        {
            name: "TRAN TUAN",
            description: "tuan",
            landscape: [tuanl],
            portrait: [tuanp],
            id: 1165
        }
    ]);
    
    const [recentIndexes, setRecentIndexes] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * backgrounds.length));
    const [nextIndex, setNextIndex] = useState(Math.floor(Math.random() * backgrounds.length));
    useEffect(() => {
        const timerId = setInterval(() => {
            if (recentIndexes.length === 0) {
                recentIndexes.push(currentIndex);
            }
            if (recentIndexes.length === 5) {
                recentIndexes.shift();
            }
            recentIndexes.push(nextIndex);
            setRecentIndexes(recentIndexes);
            setCurrentIndex(nextIndex);

            let newIndex = Math.floor(Math.random() * backgrounds.length);
            while(recentIndexes.includes(newIndex)) {
                newIndex = Math.floor(Math.random() * backgrounds.length);
            }
            setNextIndex(newIndex);
            onClickNext();
        }, 5000);
        return () => clearInterval(timerId);
    });

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

    const returnBackground = () => {
        if(window.matchMedia("(orientation: portrait)").matches) {
            return ({
                "backgroundImage": `url(${backgrounds[currentIndex].portrait[0]})`,
                "background-repeat": "no-repeat",
                "backgroundSize": "cover",
                "transition": "background-image 0.5s ease-in-out",
                "WebkitTransition": "background-image 0.5s ease-in-out"
            });
        } else {
            return ({
                "backgroundImage": `url(${backgrounds[currentIndex].landscape[0]})`,
                "backgroundSize": "cover",
                "WebkitTransition": "background-image 0.5s ease-in-out",
                "transition": "background-image 0.5s ease-in-out"
            });
        }
    }

    const onClickNext = () => {
        if(currentMeetIndex < meets.length - 1) {
            setCurrentMeetIndex(currentMeetIndex + 1);
        } else {
            setCurrentMeetIndex(0);
        }
    };

    const onClickPrevious = () => {
        if(currentMeetIndex === 0) {
            setCurrentMeetIndex(meets.length - 1);
        } else {
            setCurrentMeetIndex(currentMeetIndex - 1);
        }
    };

    return (
    <main>
        <div className="component-container" style={returnBackground()}>
            <Header language={language} setLanguage={setLanguage} />
            <div className="main-container">
                <div className="athlete-container">
                    <div className="news">
                        <p className="newstitle">{labels['featured-athlete'][language["language"]]}</p>
                        <div className="main-line"></div>
                        <div className="news-container">
                            <Link key={1} to={"/athletes/" + backgrounds[currentIndex].id} className="title athlete-hover">{backgrounds[currentIndex].name}</Link>
                            <p className="date">{labels[backgrounds[currentIndex].description][language["language"]]}</p>
                        </div>
                    </div>
                </div>
                <div className="news-competitions-container">
                    <div className="news">
                        <p className="newstitle">{labels['news'][language["language"]]}</p>
                        <div className="main-line"></div>
                        <div className="news-container">
                            <p className="title">{title[language["language"]]}</p>
                            <p className="date">{date[language["language"]]}</p>
                            <p className="preview">{content[language["language"]]}</p>
                            <p className="read-more">Lire la suite</p>
                        </div>
                    </div>

                    {
                        meets.length > 0 &&<div className="competitions-container">
                            <p className="newstitle">{labels['coming-competitions'][language["language"]]}</p>
                            <div className="competition-container">
                                <div className="main-line"></div>
                                <div className="competition">
                                    <div className="arrow" onClick={() => onClickPrevious()}><span className="competition-arrow">&#60;</span></div>
                                    <div className="competition-content">
                                        <p className="competition-title">{truncateText(meets[currentMeetIndex].eventName, 35)}</p>
                                        <p className="info">{meets[currentMeetIndex].venueName}</p>
                                        <p className="info">
                                            {
                                            meets[currentMeetIndex].startDate &&
                                                meets[currentMeetIndex].startDate.trim() !== '' ?
                                                    meets[currentMeetIndex].startDate.substring(0, 10) : 'N/A'
                                            }
                                        </p>
                                    </div>
                                    <div className="arrow" onClick={() => onClickNext()}><span className="competition-arrow">&#62;</span></div>
                                </div>
                                <Link key={2} to={"/meets"} className="inscription">{labels['registration'][language["language"]]}</Link>
                            </div>

                            <div className="no-competition-container">
                                <div className="main-line"></div>
                                <div className="competition">
                                    <div className="competition-content">
                                        <p className="competition-title">{labels['no-competition-title'][language["language"]]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <img src={backgrounds[nextIndex].landscape[0]} className="preload" alt=""/>
            <img src={backgrounds[nextIndex].portrait[0]} className="preload" alt=""/>
        </div>
    </main>
    );
}

export default Main;
