import {useState} from 'react'
import useFetch from '../../hooks/useFetch';
import { fetchReferees } from '../../services/api';
import './referees.css';

const Referees = ({language}) => {

    const { data: referees, loading, error } = useFetch(fetchReferees);

    const [labels] = useState({
        "referees": {"french": "ARBITRES", "english": "REFEREES"},
        "training": {"french": "En Formation", "english": "Training"}
    })

    const [imageErrors, setImageErrors] = useState({});

    const handleImageError = (name) => {
      setImageErrors(prevErrors => ({ ...prevErrors, [name]: true }));
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const filterRefereesByLevel = (level) => referees.filter(referee => referee.level === level);

    const renderReferees = (referees, title) => (
        <div className="referees-group-container">
          <h3>{title}</h3>
          <div className="referees">
            {referees.map((referee) => (
              <div className="referee" key={referee.name}>
                <h3>{referee.name}</h3>
                {referee.image && !imageErrors[referee.name] ? (
                  <img
                    src={`/assets/${referee.image}`}
                    alt={referee.name}
                    onError={() => handleImageError(referee.name)}
                  />
                ) : (<br></br>)}
                <p>{referee.category}</p>
                <p>{referee.region}</p>
              </div>
            ))}
          </div>
        </div>
      );

    return (
        <div className="referees-container">
        <h1>{labels['referees'][language["language"]]}</h1>
  
        <div className="bubble-container">
          <div className="content-wrapper-on">
            {renderReferees(filterRefereesByLevel('ipf'), 'International (IPF)')}
          </div>
        </div>
  
        <div className="bubble-container">
          <div className="content-wrapper-on">
            {renderReferees(filterRefereesByLevel('cpu'), 'National (CPU)')}
          </div>
        </div>
  
        <div className="bubble-container">
          <div className="content-wrapper-on">
            {renderReferees(filterRefereesByLevel('fqd'), 'Provincial (FQD)')}
          </div>
        </div>
  
        <div className="bubble-container">
          <div className="content-wrapper-on">
            {renderReferees(filterRefereesByLevel('training'), labels['training'][language["language"]])}
          </div>
        </div>
      </div>
    );
};

export default Referees
