import './footer.css';
import logo from '../assets/blue.png'
import facebook from '../assets/facebook.svg'
import instagram from '../assets/instagram.svg'
import cpu from '../assets/cpu.png'
import ipf from '../assets/ipf.png'
import mels from '../assets/mels.png'
import sq from '../assets/sport-quebec.png'
import yt from '../assets/yt.png'

import {useState} from 'react'

function Footer({language}) {

    const [labels] = useState({"about": {"french": "À propos", "english": "About"},
                                            "news": {"french": "Nouvelles", "english": "News"},
                                            "membership": {"french": "Devenir membre", "english": "Membership"},
                                            "affiliations": {"french": "Affiliée à", "english": "Affiliated to"},
                                            "partners": {"french": "Partenaires", "english": "Partners"}
                                        });

    return (
        <footer>
            <div className="footer-container">
                <img src={logo} alt="" className="logo" />
                <p className="semibold mobile-title">Fédération Québécoise de Dynamophilie</p>
                <br /><br />
                <p className="semibold">{labels['affiliations'][language["language"]]}</p>
                <div className="affiliations">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.powerlifting.sport/">
                        <img src={ipf} alt="" className="ipf-logo" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.powerlifting.ca/cpu/">
                        <img src={cpu} alt="" className="cpu-logo" />
                    </a>
                </div>
                <br /><br />
                <p className="semibold">{labels['partners'][language["language"]]}</p>
                <div className="affiliations">
                    <a target="_blank" rel="noopener noreferrer" href="http://www.education.gouv.qc.ca/organismes-de-loisir-et-de-sport/">
                        <img src={mels} alt="" className="mels-logo" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="http://www.sportsquebec.com/">
                        <img src={sq} alt="" className="sq-logo" />
                    </a>
                </div>
                <br />
                <div className="social-media">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/dynamophilie/">
                        <img src={facebook} alt="" className="icon" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/fqd.dynamophilie/">
                        <img src={instagram} alt="" className="icon" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCpu24t6qaKCizH3BAXv0SOQ">
                        <img src={yt} alt="" className="icon" />
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
