import {Link} from 'react-router-dom'
import {useState, useEffect, useLayoutEffect } from 'react'
import useFetch from '../../hooks/useFetch';
import { fetchMeetParticipants, fetchMeetCapacity } from '../../services/api';
import './participants.css';

const Participants = ({language, meetId}) => {

    const { data: participantsData, loading: participantsLoading, error: participantsError } = useFetch(fetchMeetParticipants, [meetId], [meetId]);
    const { data: capacityData, loading: capacityLoading, error: capacityError } = useFetch(fetchMeetCapacity, [meetId], [meetId]);

    const [limit, setLimit] = useState(0);
    const [participants, setParticipants] = useState([]);
    const [sortType, setSortType] = useState('any');

    const [labels] = useState({
        "participants": {"french": "LISTE DES PARTICIPANTS", "english": "LIST OF PARTICIPANTS"},
        "name": {"french": "Nom", "english": "Name"},
        "last": {"french": "Nom", "english": "Last name"},
        "first": {"french": "Prénom", "english": "First name"},
        "gender": {"french": "Genre", "english": "Gender"},
        "ac": {"french": "Classe d'âge", "english": "Age class"},
        "wc": {"french": "Classe de poids", "english": "Weight class"},
        "M": {"french": "Hommes", "english": "Men"},
        "F": {"french": "Femmes", "english": "Women"},
        "Dynamophilie": {"french": "Dynamophilie", "english": "Powerlifting"},
        "Bench Press": {"french": "Bench Press", "english": "Bench Press"},
        "Classique": {"french": "Classique", "english": "Classic"},
        "Équipé": {"french": "Équipé", "english": "Equipped"}
    });

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (participantsData) {
          setParticipants(participantsData);
        }
    }, [participantsData]);
    
    useEffect(() => {
        if (capacityData) {
            setLimit(capacityData.capacity);
        }
    }, [capacityData]);

    const sortParticipants = (a, b) => {
        switch (sortType) {
        case 'nameUp':
            return a.name.localeCompare(b.name);
        case 'nameDown':
            return b.name.localeCompare(a.name);
        case 'clubUp':
            return a.club.localeCompare(b.club);
        case 'clubDown':
            return b.club.localeCompare(a.club);
        case 'genderUp':
            return a.gender.localeCompare(b.gender);
        case 'genderDown':
            return b.gender.localeCompare(a.gender);
        case 'acUp':
            return a.ageClass.localeCompare(b.ageClass);
        case 'acDown':
            return b.ageClass.localeCompare(a.ageClass);
        case 'typeUp':
            return a.type.localeCompare(b.type);
        case 'typeDown':
            return b.type.localeCompare(a.type);
        case 'divisionUp':
            return a.division.localeCompare(b.division);
        case 'divisionDown':
            return b.division.localeCompare(a.division);
        case 'wcUp':
            return a.weightClass.localeCompare(b.weightClass);
        case 'wcDown':
            return b.weightClass.localeCompare(a.weightClass);
        default:
            return 0;
        }
      };
    
      const sortedParticipants = [...participants].sort(sortParticipants);


    if (participantsLoading || capacityLoading) return <p>Loading...</p>;
    if (participantsError || capacityError) return <p>Error: {participantsError?.message || capacityError?.message}</p>;

    return (
        <div className="participants-container">
            <h2>{labels['participants'][language["language"]]}</h2>

            <p>Participants: {participants.length} / {limit}</p>
            <div className="table-container">
                <div className="table-wrapper">
                    <table>
                        <tbody>
                            <tr>
                                <th className="pointer" onClick={() => setSortType(sortType === "nameDown" ? "nameUp" : "nameDown")}>{labels['name'][language["language"]]}</th>
                                {meetId >= 220 && (
                                    <th className="pointer" onClick={() => setSortType(sortType === "clubDown" ? "clubUp" : "clubDown")}>Club</th>
                                )}
                                <th className="pointer" onClick={() => setSortType(sortType === "genderDown" ? "genderUp" : "genderDown")}>{labels['gender'][language["language"]]}</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "acDown" ? "acUp" : "acDown")}>{labels['ac'][language["language"]]}</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "typeDown" ? "typeUp" : "typeDown")}>Type</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "divisionDown" ? "divisionUp" : "divisionDown")}>Division</th>
                                <th className="pointer" onClick={() => setSortType(sortType === "wcDown" ? "wcUp" : "wcDown")}>{labels['wc'][language["language"]]}</th>
                            </tr>

                            { 
                            // eslint-disable-next-line
                                sortedParticipants.map((participant, index) => {
                                    if(meetId <= 213) {
                                        return (
                                            <tr key={index}>
                                                <td key={index+1}><Link to={"/athletes/" + participant.athleteId} key={index+2}>{participant["first"] + " " + participant["last"]}</Link></td>
                                                <td key={index+8}>{participant["club"]}</td>
                                                <td key={index+3}>{labels[participant["gender"]][language["language"]]}</td>
                                                <td key={index+4}>{participant["ac"]}</td>
                                                <td key={index+5}>{labels[participant["type"]][language["language"]]}</td>
                                                <td key={index+6}>{labels[participant["division"]][language["language"]]}</td>
                                                <td key={index+7}>{participant["wc"]}</td>
                                            </tr>
                                        )
                                    } else {
                                        return (                
                                            <tr key={index}>
                                                {participant.isNovice === false ? 
                                                (
                                                    <td key={index+1}><Link to={"/athletes/" + participant["athleteId"]} key={index+2}>{participant["name"]}</Link></td>
                                                ) : (
                                                    <td key={index+1}>{participant["name"]}</td>
                                                )}
                                                <td key={index+8}><Link to={"/clubs/" + participant["clubId"]} key={index+2}>{participant["club"]}</Link></td>
                                                <td key={index+3}>{labels[participant["gender"]][language["language"]]}</td>
                                                <td key={index+4}>{participant["ageClass"]}</td>
                                                <td key={index+5}>{labels[participant["type"]][language["language"]]}</td>
                                                <td key={index+6}>{labels[participant["division"]][language["language"]]}</td>
                                                <td key={index+7}>{participant["weightClass"]}</td>
                                            </tr>
                                        )
                                    }  
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Participants
