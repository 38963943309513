import React from 'react';
import { useRef, useState } from 'react';
import './styles/memberOrNoviceSelection.css';

const MemberOrNoviceSelection = ({ handleSelection, language }) => {

    const questionMark = useRef();
    const noviceBox = useRef();
    const isNew = useRef();
    const isNotNew = useRef();
    const noviceContainer = useRef();
    const isNovice = useRef();
    const isNotNovice = useRef();

    const [labels] = useState({
        "first-time": {
            "french": "Est-ce votre première compétition dans la FQD ?",
            "english": "Is this your first competition in the FQD?"
        },
        "novice": {
            "french": "Voulez-vous vous inscrire comme novice ?",
            "english": "Do you want to register as a novice?"
        },
        "p1": {
            "french": "Lors d’une première compétition, un nouveau leveur peut demander le statut \"Novice\". Ce statut permet d’essayer le sport de la dynamophilie sans être dans l’obligation d’avoir une carte de membre et tous les équipements obligatoires.",
            "english": "In a first competition, a new lifter can apply for \"Novice\" status. This status allows you to try out the sport of powerlifting without being required to have a membership card and all mandatory equipment."
        },
        "p2": {
            "french": "Avantages d'être novice",
            "english": "Benefits of being a novice"
        },
        "ad1": {
            "french": "La carte de membre n'est pas obligatoire.",
            "english": "The membership card is not compulsory."
        },
        "ad2": {
            "french": "L'uniforme de leveur standard n'est pas obligatoire (singlet, bas de deadlift, etc). Par contre vous devez avoir une culotte courte et un t-shirt.",
            "english": "The standard lifting uniform is not mandatory (singlet, deadlift bottom, etc.). On the other hand, you must have short pants and a t-shirt."
        },
        "ad3": {
            "french": "Vous n'êtes pas éligible au test anti-dopage et n'avez pas à payer le 15$.",
            "english": "You are not eligible for the anti-doping test and do not have to pay the $15."
        },
        "p3": {
            "french": "Désavantages d'être novice",
            "english": "Disadvantages of being a novice"
        },
        "dis1": {
            "french": "Aucun standard de qualification ne seront pris en compte pour le championnat provincial et canadien.",
            "english": "No qualification standard will be taken into account for the provincial and Canadian championships."
        },
        "dis2": {
            "french": "Aucun record ne sera comptabilisé.",
            "english": "No record will be counted."
        },
        "yes": {
            "french": "Oui",
            "english": "Yes"
        },
        "no": {
            "french": "Non",
            "english": "No"
        }
    });

    const handleIsNew = () => {
        if(isNotNew.current.className === "selected") {
            noviceContainer.current.className = "question";
            isNotNew.current.className = "";
            isNew.current.className = "selected";
            handleSelection('memberForm');
        } else {
            questionMark.current.className = "explanation-off";
            noviceBox.current.className = "hide";
            noviceContainer.current.className = "hide question";
            isNotNew.current.className = "selected";
            isNew.current.className = "";

            isNotNovice.current.className = "selected";
            isNovice.current.className = "";
            handleSelection('memberForm');
        }
    };

    const handleIsNovice = () => {
        if(isNotNovice.current.className === "selected") {
            isNotNovice.current.className = "";
            isNovice.current.className = "selected";
            handleSelection('noviceForm');
        } else {
            isNotNovice.current.className = "selected";
            isNovice.current.className = "";
            handleSelection('memberForm');
        }
    };


    const handleNoviceDetails = () => {
        if(questionMark.current.className === "explanation-off") {
            questionMark.current.className = "explanation-on";
            noviceBox.current.className = "novice-text";
        } else {
            questionMark.current.className = "explanation-off";
            noviceBox.current.className = "hide";
        }
    };

    return (
        <div className="novice-or-member-container">
            <div className="first-time card-container">
                <div className="question">
                    <label>{labels['first-time'][language["language"]]}</label>
                    <div className="btn-group">
                        <button onClick={() => handleIsNew()} ref={isNew}>{labels['yes'][language["language"]]}</button>
                        <button onClick={() => handleIsNew()} ref={isNotNew} className="selected">{labels['no'][language["language"]]}</button>
                    </div>
                </div>

                <div  ref={noviceContainer} className="hide question">
                    <label>{labels['novice'][language["language"]]}</label>
                    <div className="btn-group">
                        <button onClick={() => handleIsNovice()} ref={isNovice}>{labels['yes'][language["language"]]}</button>
                        <button onClick={() => handleIsNovice()} ref={isNotNovice} className="selected">{labels['no'][language["language"]]}</button>
                        <button ref={questionMark} onClick={() => handleNoviceDetails()} className="explanation-off">?</button>
                    </div>
                </div>
                <div ref={noviceBox} className="hide">
                    <p>{labels['p1'][language["language"]]}</p>

                    <p className="bold">{labels['p2'][language["language"]]}</p>
                    <ul>
                        <li>{labels['ad1'][language["language"]]}</li>
                        <li>{labels['ad2'][language["language"]]}</li>
                        <li>{labels['ad3'][language["language"]]}</li>
                    </ul>

                    <p className="bold">{labels['p3'][language["language"]]}</p>
                    <ul>
                        <li>{labels['dis1'][language["language"]]}</li>
                        <li>{labels['dis2'][language["language"]]}</li>
                    </ul>
                </div>
            </div>

            <div className="line"></div>
        </div>
    );
};

export default MemberOrNoviceSelection;