import './guide.css';
import {useLayoutEffect, useRef, useState} from 'react'

import {Link} from 'react-router-dom'

const Guide = ({language}) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const video = useRef();
    const videoButton = useRef();

    const inscription = useRef();
    const inscriptionButton = useRef();

    const novice = useRef();
    const noviceButton = useRef();

    const age = useRef();
    const ageButton = useRef();

    const weight = useRef();
    const weightButton = useRef();

    const weighin = useRef();
    const weighinButton = useRef();

    const equipment = useRef();
    const equipmentButton = useRef();

    const groups = useRef();
    const groupsButton = useRef();

    const start = useRef();
    const startButton = useRef();

    const onClick = (button, div) => {
        if(div.current.className === "content-wrapper-on") {
            button.current.innerHTML = "&plus;";
            div.current.className = "content-wrapper-off";
        } else {
            button.current.innerHTML = "&minus;";
            div.current.className = "content-wrapper-on";;
        }
    };

    const [labels] = useState({
        "content": {
            "french": [
                <div key={3} className="athlete-guide-container">
                    <h1>GUIDE DE L'ATHLÈTE</h1>

                    <h2>DÉBUTANTS</h2>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(noviceButton, novice)}>
                            <h3>Membre ou novice ?</h3>
                            <button ref={noviceButton}>&#43;</button>
                        </div>

                        <div ref={novice} className="content-wrapper-off">
                            <p>Lorsqu’un athlète remplit le formulaire pour s’inscrire à sa première compétition, il peut demander à avoir le statut de « novice ». Celui-ci permet de participer à une compétition de dynamophilie sans être membre de la Fédération québécoise de dynamophilie et de compétitionner en portant une tenue sportive et des pièces d’équipement qui ne seraient pas conformes aux règlements.</p>

                            <h3>Avantages d'être novice</h3>
                            <ul>
                                <li>La carte de membre n’est pas obligatoire.</li>
                                <li>L’uniforme de leveur standard n’est pas obligatoire (combinaison, ceinture, etc). Par contre vous devez avoir une culotte courte et un t-shirt.</li>
                                <li>Vous n’êtes pas éligible au test anti-dopage.</li>
                            </ul>

                            <h3>Désavantage d'être novice</h3>
                            <ul>
                                <li>Les résultats de l’athlète pour la compétition ne seront pas pris en compte pour la qualification aux championnats provincial, central et national.</li>
                                <li>Si l’athlète réussissait un lever dont la charge excédait un record précédemment établi, celui-ci ne serait pas reconnu comme un nouveau record.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(videoButton, video)}>
                            <h3>Vidéos Instructives</h3>
                            <button ref={videoButton}>&#43;</button>
                        </div>

                        <div ref={video} className="content-wrapper-off">
                            <ul>
                                <li><a key={2} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=3QEwUFx6luI&t=123s">Équipement obligatoire en compétition</a></li>
                                <li><a key={2} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=mETDjy8uhgM">Règlements et fautes techniques en compétition</a></li>
                            </ul>
                        </div>
                    </div>

                    <h2>DÉROULEMENT D'UNE COMPÉTITION</h2>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(inscriptionButton, inscription)}>
                            <h3>Inscription</h3>
                            <button ref={inscriptionButton}>&#43;</button>
                        </div>

                        <div ref={inscription} className="content-wrapper-off">
                            <p>L’inscription à une compétition doit être effectuée à l’avance. Les formulaires d’inscription sont accessibles en ligne dans la section <Link to="/upcoming-meets">Compétitions à venir</Link> du site.</p>

                            <br/><br/>

                            <p>Le paiement pour l’inscription doit être effectué à l’attention du responsable de compétition. Les modes de paiement acceptés (chèque, virement Interac, etc.) sont au choix de l’organisateur. Le compétiteur recevra une confirmation d’inscription uniquement lorsque le responsable de compétition aura reçu le paiement.</p>

                            <ul>
                                <li>En cas de paiement par chèque, veuillez prévenir l’organisateur de l’envoi du chèque par la poste.</li>
                                <li>En cas de paiement par virement Interac, veuillez envoyer un courriel à l’organisateur contenant les informations nécessaires au dépôt des fonds (question de sécurité, réponse à la question de sécurité).</li>
                            </ul>

                            <p>Si vous n’êtes pas novice, vous devez avoir une carte de membre active. Voir la section membre ou novice plus bas. 
                                
                            <br/><br/>
                            Cliquez sur les liens suivants si vous n’êtes pas à l’aise avec les classes de poids et les catégories d’âge.</p>
                        </div>
                    </div>

                    <h2>COMMENT DÉTERMINER VOTRE CLASSE DE POIDS ET D'ÂGE ?</h2>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(ageButton, age)}>
                            <h3>Catégorie d'âge</h3>
                            <button ref={ageButton}>&#43;</button>
                        </div>

                        <div ref={age} className="content-wrapper-off">
                            <p><strong>Hommes et Femmes</strong> <br/><br/>
                            <strong>Open</strong><br/>
                            Du jour anniversaire des 19 ans et au-delà, sans tenir compte des catégories d’âge spécifiques. 

                            <br/><br/>
                            
                            <strong>Sub-juniors</strong><br/>
                            Du jour anniversaire des 14 ans jusqu’au 31 décembre inclus de l’année des 18 ans.

                            <br/><br/>
                            
                            <strong>Juniors</strong><br/>
                            Du 1er janvier de l’année des 19 ans jusqu’au 31 décembre inclus de l’année des 23 ans.

                            <br/><br/>
                            
                            <strong>Masters 1</strong><br/>
                            Du 1er janvier de l’année des 40 ans jusqu’au 31 décembre inclus de l’année des 49 ans.

                            <br/><br/>
                            
                            <strong>Masters 2</strong><br/>
                            Du 1er janvier de l’année des 50 ans jusqu’au 31 décembre inclus de l’année des 59 ans.

                            <br/><br/>
                            
                            <strong>Masters 3</strong><br/>
                            Du 1er janvier de l’année des 60 ans jusqu’au 31 décembre inclus de l’année des 69 ans.

                            <br/><br/>
                            
                            <strong>Masters 4</strong><br/>
                            Du 1er janvier de l’année des 70 ans et au-delà. Cette catégorie n’existe pas pour chaque catégorie de poids et elle est récompensée uniquement à l’indice. 

                            <br/><br/> <br/><br/>
                            
                            Les compétitions pour les athlètes de moins de 14 ans peuvent être organisées par les fédérations nationales. <br/><br/>
                            
                            Le classement dans toutes les catégories d’âges sera déterminé par les totaux réalisés, dans le respect du règlement. Chaque fédération nationale a le loisir d’adapter, à sa discrétion, les catégories d’âges, ou de créer des sous-catégories.</p>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(weightButton, weight)}>
                            <h3>Catégories de poids</h3>
                            <button ref={weightButton}>&#43;</button>
                        </div>

                        <div ref={weight} className="content-wrapper-off">
                            <div className="weight-classes-container">
                                <div className="box border-right">
                                    Hommes<br/><br/>
                                    -59kg À partir de 0 kg jusqu’à 59.00 kg<br/>
                                    -66kg À partir de 59.01 kg jusqu’à 66.00 kg<br/>
                                    -74kg À partir de 66.01 kg jusqu’à 74.00 kg<br/>
                                    -83kg À partir de 74.01 kg jusqu’à 83.00 kg<br/>
                                    -93kg À partir de 83.01 kg jusqu’à 93.00 kg<br/>
                                    -105kg À partir de 93.01 kg jusqu’à 105.00 kg<br/>
                                    -120kg À partir de 105.01 kg jusqu’à 120.00 kg<br/>
                                    +120kg À partir de 120.01 kg jusqu’à sans limite<br/><br/>
                                    (Sub-Junior et Junior seulement)<br/>
                                    -53kg À partir de 0 kg jusqu’à 53.00 kg<br/><br/>
                                </div>

                                <div className="box">
                                    Femmes<br/><br/>
                                    -47kg À partir de 0 kg jusqu’à 47.00 kg<br/>
                                    -52kg À partir de 47.01 kg jusqu’à 52.00 kg<br/>
                                    -57kg À partir de 52.01 kg jusqu’à 57.00 kg<br/>
                                    -63kg À partir de 57.01 kg jusqu’à 63.00 kg<br/>
                                    -69kg À partir de 63.01 kg jusqu’à 69.00 kg<br/>
                                    -76kg À partir de 69.01 kg jusqu’à 76.00 kg<br/>
                                    -84kg À partir de 76.01 kg jusqu’à 84.00 kg<br/>
                                    +84kg À partir de 84.01 kg jusqu’à sans limite<br/><br/>
                                    (Sub-Junior et Junior seulement)<br/>
                                    -43kg À partir de 0 kg jusqu’à 43.00 kg<br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>



                    <h2>LA COMPÉTITION</h2>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(weighinButton, weighin)}>
                            <h3>La pesée</h3>
                            <button ref={weighinButton}>&#43;</button>
                        </div>

                        <div ref={weighin} className="content-wrapper-off">
                            <p>La pesée débute 2 heures avant le début de la compétition (pour une catégorie donnée), selon un ordre aléatoire établi préalablement à la compétition. Tous les athlètes de la catégorie doivent être présents à la pesée, qui sera effectuée en présence de deux ou trois arbitres désignés pour cela.<br/><br/>
                                
                            La pesée dure 1 heure 30 minutes et se déroule dans un local fermé. Les seules personnes autorisées dans le local sont l’athlète, son entraîneur ou le directeur de l’équipe, ainsi que les deux ou trois arbitres de service. Pour des raisons d’hygiène, l’athlète devra se peser en chaussettes, ou mettre sous ses pieds du papier. L’athlète doit présenter une pièce d’identité avec photo lors de la pesée.<br/><br/>
                            
                            Les athlètes peuvent être pesés nus ou en sous-vêtements (s’ils sont conformes aux règlements et qu’ils ne modifient pas leur poids. En cas de doute sur le poids des sous-vêtements, une 2ième pesée sera exigée et l’athlète devra être pesé nu. Pendant la pesée, les arbitres et les officiels supplémentaires doivent être du même sexe que celui de l’athlète.<br/><br/>
                            
                            Les leveurs handicapés ou amputés qui participent à des compétitions de développé couché (bench press) verront leur poids corporel augmenté des pourcentages suivants, selon les cas :</p>

                            <ul>
                                <li>Pour chaque amputation au-dessous de la cheville : 1/54ème du poids de corps en plus ;</li>
                                <li>Pour chaque amputation au-dessous du genou : 1/36ème du poids de corps en plus ;</li>
                                <li>Pour chaque amputation au-dessus du genou : 1/18ème du poids de corps en plus ;</li>
                                <li>Pour chaque désarticulation de la hanche : 1/9ème du poids de corps en plus ;</li>
                                <li>Les athlètes qui présentent des dysfonctionnements des membres inférieurs nécessitant des attaches pour les jambes ou autre chose pour marcher seront pesés avec cet équipement, sans pourcentage supplémentaire.</li>
                            </ul>

                            <p>Chaque athlète ne peut être pesé qu’une seule fois. Seuls les leveurs dont le poids corporel est inférieur au poids minimum ou supérieur au poids maximum de la catégorie dans laquelle ils compétitionnent peuvent être pesés à nouveau, et ce dans l’heure et demie prévue pour la pesée (sinon ils seront éliminés de la compétition). Un athlète ne peut être repesé que dans la limite de temps impartie et dans le respect du tirage au sort.<br/><br/>
                                
                            Un leveur ne pourra être repesé en dehors de cette limite que dans le cas où il n’aurait pas pu monter sur la balance à cause du grand nombre d’athlètes qui n’étaient pas au poids et devaient être également repesés. Les résultats de la pesée ne sont communiqués publiquement que lorsque tous les athlètes de la catégorie ont été pesés. Un leveur ne peut se peser que dans la catégorie pour laquelle il a été inscrit dans les nominations finales, et ce au plus tard 21 jours avant la compétition.<br/><br/>
                            
                            Au maximum 30 minutes avant le début de la compétition, les athlètes devront vérifier la hauteur des supports pour la flexion des jambes (squat) et le développé couché (bench press), ainsi que l’opportunité d’utiliser des cales sous les pieds au développé couché ; puis en fournir les résultats aux arbitres. La feuille des hauteurs de supports devra être signée par l’athlète ou son coach.</p>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(equipmentButton, equipment)}>
                            <h3>Vérification de l'équipement</h3>
                            <button ref={equipmentButton}>&#43;</button>
                        </div>

                        <div ref={equipment} className="content-wrapper-off">
                            <p>La vérification de l’équipement se fait généralement durant la pesée. Toutes les pièces d’équipement que vous aurez sur la plate-forme doivent être vérifiées afin de valider leur conformité aux règlements. Si vous avez des doutes sur certaines pièces de votre équipement, n’hésitez pas à consulter les arbitres et officiels. Les pièces d’équipement non-conformes aux règlements ne seront pas tolérées.<br/><br/>
                                
                            La liste des équipements permis est disponible sur le site de l’International Powerlifting Federation (IPF), à l’adresse suivante :<br/><br/>
                            
                            <a key={1} target="_blank" rel="noopener noreferrer" href="https://www.powerlifting.sport/rulescodesinfo/approved-list.html">https://www.powerlifting.sport/rulescodesinfo/approved-list.html</a></p>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(groupsButton, groups)}>
                            <h3>Groupes et vagues</h3>
                            <button ref={groupsButton}>&#43;</button>
                        </div>

                        <div ref={groups} className="content-wrapper-off">
                            <p>Lors d’une compétition, les compétiteurs sont divisés d’abord en différents groupes, puis chaque groupe en différentes vagues. Une vague est constituée au maximum de 14 athlètes. L’heure de la pesée est déterminée en fonction du groupe dans laquelle l’athlète se trouve.</p>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(startButton, start)}>
                            <h3>Début de la compétition</h3>
                            <button ref={startButton}>&#43;</button>
                        </div>

                        <div ref={start} className="content-wrapper-off">
                            <p>La compétition débute 2 heures après le début de la pesée, et ce pour chaque groupe. <br/><br/>
                                
                            Par exemple : <br/>
                            Groupe A<br/>
                            Début de la pesée : 8h<br/>
                            Début de la compétition : 10h<br/><br/>
                            
                            Groupe B<br/>
                            Début de la pesée : 12h<br/>
                            Début de la compétition : 14h<br/><br/>
                            
                            Dans le cas où un groupe serait divisé en plusieurs vagues, ce sont les athlètes de la première vague qui débuteront la compétition. Les leveurs qui constituent la seconde vague seront appelés sur la plateforme uniquement lorsque les athlètes de la première vague auront complété leurs trois essais pour un levé (par exemple le squat).<br/><br/>
                            
                            Par exemple :<br/>
                            Vague A : Squat à 10h<br/>
                            Vague B : Squat à 10h45<br/><br/>
                            
                            Les athlètes sont appelés sur la plateforme par l’annonceur de la compétition par ordre croissant de charges. L’appel se fait ainsi : La barre est chargée pour « X » (athlète appelé sur le plateau de compétition). En attente : « Y » (prochain athlète qui sera appelé à effectuer un lever). En préparation : « Z » (athlète qui sera appelé suite à « Y »).<br/><br/>
                            
                            L’athlète appelé sur la plateforme dispose d’une minute pour débuter son essai. Au moment où le lever est terminé (qu’il soit réussi ou non), l’athlète dispose d’une minute pour indiquer la charge (en kilogrammes) de son prochain essai sur « un carton d’essai », qu’il devra remettre à la table de l’annonceur. Le carton devra impérativement contenir le nom de l’athlète ainsi que sa signature ou celle de son entraîneur.<br/><br/>
                            
                            Dans le cas où l’athlète aurait réussi le lever, mais que le carton d’essai n’aurait pas été remis dans la minute, 2,5kg seront ajoutés automatiquement à la charge. Dans le cas où l’athlète n’aurait pas réussi le lever et que le carton d’essai n’aurait pas été remis dans la minute, la charge demeurerait la même.<br/><br/>
                            
                            Pour éviter que cette situation, il est recommandé aux athlètes de préparer une feuille contenant les charges qu’ils projettent pour chacun des trois essais pour les trois différents levers (squat, bench press et deadlift), selon divers scénarios de performance (pessimiste, réaliste, optimiste).</p>
                        </div>
                    </div>
                </div>
            ], 
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            "english": [
                <div key={4} className="athlete-guide-container">
                    <h1>ATHLETE GUIDE</h1>
                    
                    <h2>BEGINNERS</h2>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(noviceButton, novice)}>
                            <h3>Member or Novice?</h3>
                            <button ref={noviceButton}>&#43;</button>
                        </div>

                        <div ref={novice} className="content-wrapper-off">
                            <p>When an athlete fills out the form to register for their first competition, they can apply for "novice" status. This allows you to participate in a powerlifting competition without being a member of the Fédération québécoise de dynamophilie and to compete wearing sportswear and equipment that does not comply with the regulations.</p>

                            <h3>Benefits of being a novice</h3>
                            <ul>
                                <li>The membership card is not compulsory.</li>
                                <li>The standard lifter's uniform is not mandatory (singlet, belt, etc.). On the other hand, you must wear shorts and a t-shirt.</li>
                                <li>You are not eligible for the anti-doping test.</li>
                            </ul>

                            <h3>Disadvantage of being a novice</h3>
                            <ul>
                                <li>The athlete's results for the competition will not be taken into account for qualification for the Provincial, Central and National Championships.</li>
                                <li>If the athlete succeeds in a lift with a load in excess of a previously set record, that record will not be recognized as a new record.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(videoButton, video)}>
                            <h3>Instructional Videos</h3>
                            <button ref={videoButton}>&#43;</button>
                        </div>

                        <div ref={video} className="content-wrapper-off">
                            <ul>
                                <li><a key={2} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=3QEwUFx6luI&t=123s">Compulsory equipment in competition</a> (french only)</li>
                                <li><a key={2} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=mETDjy8uhgM">Rules and technical faults in competition</a> (french only)</li>
                            </ul>
                        </div>
                    </div>

                    <h2>CONDUCT OF A COMPETITION</h2>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(inscriptionButton, inscription)}>
                            <h3>Registration</h3>
                            <button ref={inscriptionButton}>&#43;</button>
                        </div>

                        <div ref={inscription} className="content-wrapper-off">
                            <p>Registration for a competition must be made in advance. Registration forms are available online in the section <Link to="/upcoming-meets">Upcoming competitions</Link> of the website.</p>

                            <br/><br/>

                            <p>Payment for registration must be made to the attention of the competition manager. The accepted payment methods (check, Interac transfer, etc.) are at the choice of the organizer. The competitor will receive a confirmation of registration only when the competition manager has received payment.</p>

                            <ul>
                                <li>If paying by check, please notify the organizer that the check will be mailed.</li>
                                <li>In case of payment by Interac transfer, please send an email to the organizer containing the information necessary for depositing the funds (security question, answer to the security question).</li>
                            </ul>

                            <p>If you are not a novice, you must have an active membership card. See the member or novice section below.
                                
                            <br/><br/>
                            Click on the following links if you are not comfortable with weight classes and age categories.</p>
                        </div>
                    </div>

                    <h2>HOW TO DETERMINE YOUR WEIGHT AND AGE CLASSES?</h2>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(ageButton, age)}>
                            <h3>Age category</h3>
                            <button ref={ageButton}>&#43;</button>
                        </div>

                        <div ref={age} className="content-wrapper-off">
                            <p><strong>Men and Women</strong> <br/><br/>
                            <strong>Open</strong><br/>
                            From the birthday of 19 years and beyond, without taking into account specific age categories. 

                            <br/><br/>
                            
                            <strong>Sub-juniors</strong><br/>
                            From the birthday of the 14th birthday until December 31st of the year of the 18th birthday.

                            <br/><br/>
                            
                            <strong>Juniors</strong><br/>
                            From January 1 of the year of the 19 years old until December 31 inclusive of the year of the 23 years old.

                            <br/><br/>
                            
                            <strong>Masters 1</strong><br/>
                            From January 1 of the year of 40 up to and including December 31 of the year of 49.

                            <br/><br/>
                            
                            <strong>Masters 2</strong><br/>
                            From January 1 of the year of 50 up to and including December 31 of the year of 59.

                            <br/><br/>
                            
                            <strong>Masters 3</strong><br/>
                            From January 1 of the year of 60 up to and including December 31 of the year of 69.

                            <br/><br/>
                            
                            <strong>Masters 4</strong><br/>
                            From January 1 of the year of 70 and beyond. This category does not exist for every weight category and is awarded only on the index. 

                            <br/><br/> <br/><br/>
                            
                            Competitions for athletes under 14 can be organized by national federations. <br/><br/>
                            
                            The classification in all age categories will be determined by the totals achieved, in accordance with the regulations. Each national federation is free to adapt, at its discretion, the age categories, or create sub-categories.</p>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(weightButton, weight)}>
                            <h3>Weight Categories</h3>
                            <button ref={weightButton}>&#43;</button>
                        </div>

                        <div ref={weight} className="content-wrapper-off">
                            <div className="weight-classes-container">
                                <div className="box border-right">
                                    Men<br/><br/>
                                    -59kg From 0 kg to 59.00 kg<br/>
                                    -66kg From 59.01 kg to 66.00 kg<br/>
                                    -74kg From 66.01 kg to 74.00 kg<br/>
                                    -83kg From 74.01 kg to 83.00 kg<br/>
                                    -93kg From 83.01 kg to 93.00 kg<br/>
                                    -105kg From 93.01 kg to 105.00 kg<br/>
                                    -120kg From 105.01 kg to 120.00 kg<br/>
                                    +120kg From 120.01 kg to without limit<br/><br/>
                                    (Sub-Junior and Junior only)<br/>
                                    -53kg From 0 kg to 53.00 kg<br/><br/>
                                </div>

                                <div className="box">
                                    Women<br/><br/>
                                    -47kg From 0 kg to 47.00 kg<br/>
                                    -52kg From 0 kg to 52.00 kg<br/>
                                    -57kg From 59.01 kg to 57.00 kg<br/>
                                    -63kg From 66.01 kg to 63.00 kg<br/>
                                    -69kg From 74.01 kg to 69.00 kg<br/>
                                    -76kg From 83.01 kg to 76.00 kg<br/>
                                    -84kg From 93.01 kg to 84.00 kg<br/>
                                    +84kg From 84.01 kg to without limit<br/><br/>
                                    (Sub-Junior and Junior only)<br/>
                                    -43kg From 0 kg to 43.00 kg<br/><br/>
                                </div>
                            </div>
                        </div>
                    </div>



                    <h2>COMPETITION DAY</h2>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(weighinButton, weighin)}>
                            <h3>The Weigh-In</h3>
                            <button ref={weighinButton}>&#43;</button>
                        </div>

                        <div ref={weighin} className="content-wrapper-off">
                            <p>The weigh-in begins 2 hours before the start of the competition (for a given category), in a random order established prior to the competition. All the athletes in the category must be present at the weigh-in, which will be carried out in the presence of two or three referees appointed for it.<br/><br/>
                                
                            The weighing lasts 1 hour 30 minutes and takes place in a closed room. The only people allowed in the room are the athlete, his coach or the team manager, as well as the two or three referees on duty. For hygienic reasons, the athlete should weigh himself in socks, or put paper under his feet. The athlete must present a photo ID at the weigh-in.<br/><br/>
                            
                            Athletes can be weighed naked or in underwear (if they comply with the regulations and do not change their weight. If in doubt about the weight of the underwear, a 2nd weigh-in will be required and the athlete must be weighed naked. During the weigh-in, the referees and additional officials must be of the same sex as the athlete.<br/><br/>
                            
                                Disabled or amputee lifters who participate in bench press competitions will have their body weight increased by the following percentages, depending on the case:</p>

                            <ul>
                                <li>For each amputation below the ankle: 1 / 54th of the body weight more;</li>
                                <li>For each amputation below the knee: 1 / 36th of the body weight more;</li>
                                <li>For each amputation above the knee: 1 / 18th of the body weight more;</li>
                                <li>For each disarticulation of the hip: 1 / 9th of the body weight more;</li>
                                <li>Athletes with lower limb dysfunction requiring leg restraints or something else to walk will be weighed with this equipment, at no additional percentage.</li>
                            </ul>

                            <p>Each athlete can only be weighed once. Only lifters whose body weight is below the minimum weight or above the maximum weight of the category in which they compete may be reweighed, within the hour and a half scheduled for the weigh-in (otherwise they will be eliminated from the competition). An athlete can only be reweighed within the time limit and in accordance with the draw.<br/><br/>
                                
                            A lifter may only be reweighed outside this limit if he could not have climbed on the scale due to the large number of athletes who were not at weight and had to be reweighed as well. The results of the weigh-in are not released publicly until all athletes in the category have been weighed. A lifter can only weigh himself in the category for which he was entered in the final nominations, and no later than 21 days before the competition.<br/><br/>
                            
                            A maximum of 30 minutes before the start of the competition, athletes should check the height of the supports for leg bending (squat) and bench press, as well as the advisability of using wedges under the feet at the bench; then provide the results to the referees. The sheet of support heights must be signed by the athlete or his coach.</p>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(equipmentButton, equipment)}>
                            <h3>Equipment Check</h3>
                            <button ref={equipmentButton}>&#43;</button>
                        </div>

                        <div ref={equipment} className="content-wrapper-off">
                            <p>Equipment check is usually done during weigh-ins. All the pieces of equipment that you will have on the platform must be checked in order to validate their compliance with the regulations. If you have any doubts about any part of your equipment, do not hesitate to consult the referees and officials. Equipment that does not comply with regulations will not be tolerated.<br/><br/>
                                
                            The list of permitted equipment is available on the International Powerlifting Federation (IPF) website, at the following address:<br/><br/>
                            
                            <a key={2} target="_blank" rel="noopener noreferrer" href="https://www.powerlifting.sport/rulescodesinfo/approved-list.html">https://www.powerlifting.sport/rulescodesinfo/approved-list.html</a></p>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(groupsButton, groups)}>
                            <h3>Groups and Flights</h3>
                            <button ref={groupsButton}>&#43;</button>
                        </div>

                        <div ref={groups} className="content-wrapper-off">
                            <p>In a competition, competitors are divided first into different groups, then each group into different waves. A wave is made up of a maximum of 14 athletes. The time of the weigh-in is determined based on the group the athlete is in.</p>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(startButton, start)}>
                            <h3>Start of the Competition</h3>
                            <button ref={startButton}>&#43;</button>
                        </div>

                        <div ref={start} className="content-wrapper-off">
                            <p>The competition begins 2 hours after the start of the weigh-in, for each group. <br/><br/>
                                
                            For example: <br/>
                            Group A <br/>
                            Start of weighing: 8 a.m. <br/>
                            Start of the competition: 10 a.m. <br/> <br/>
                            
                            Group B <br/>
                            Start of weighing: 12 p.m. <br/>
                            Start of the competition: 2 p.m. <br/> <br/>
                            
                            In the event that a group is divided into several waves, it is the athletes of the first wave who will start the competition. The lifters who make up the second wave will be called to the platform only when the athletes in the first wave have completed their three tries for a lift (eg the squat). <br/> <br/>
                            
                            For example: <br/>
                            Wave A: Squat at 10 a.m. <br/>
                            Wave B: Squat at 10:45 am <br/> <br/>
                            
                            Athletes are called to the platform by the competition announcer in ascending order of load. The call is made as follows: The bar is loaded for "X" (athlete called to the competition platform). Pending: “Y” (next athlete who will be called to lift). In preparation: “Z” (athlete who will be called after “Y”). <br/> <br/>
                            
                            The athlete called to the platform has one minute to begin his attempt. By the time the lift is complete (whether successful or not), the athlete has one minute to indicate the load (in kilograms) of his next attempt on "a trial card", which he will have to return to the announcer table. The card must contain the name of the athlete as well as his signature or that of his coach. <br/> <br/>
                            
                            In the event that the athlete has successfully lifted it, but the test card has not been returned within one minute, 2.5kg will be automatically added to the load. In the event that the athlete has not successfully lifted it and the test card is not returned within one minute, the load will remain the same. <br/> <br/>
                            
                            To avoid this situation, it is recommended that athletes prepare a sheet containing the loads they project for each of the three tries for the three different lifts (squat, bench press and deadlift), under various performance scenarios (pessimistic, realistic , optimistic). </p>
                        </div>
                    </div>
                </div>
            ]}
    })

    return (
        <>
            {labels['content'][language["language"]]}
        </>
    );
}

export default Guide
