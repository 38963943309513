import axios from 'axios';

import { API_BASE_URL, AUTH_HEADER } from '../config';

const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

apiClient.interceptors.response.use(
    response => response,
    error => {
        console.error('API Error:', error);
        return Promise.reject(error);
    }
);

export const fetchMeets = async (status) => {
    try {
        const response = await apiClient.get('/meets', {
            params: { status }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching meets:', error);
        throw error;
    }
};

export const fetchMeetById = async (meetId) => {
    try {
        const response = await apiClient.get(`/meets/${meetId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching meet by ID:', error);
        throw error;
    }
};

export const fetchResultsByMeetId = async (meetId) => {
    try {
        const response = await apiClient.get(`/meets/${meetId}/results`);
        return response.data;
    } catch (error) {
        console.error('Error fetching meet results:', error);
        throw error;
    }
};

export const fetchResultsByAthleteId = async (id) => {
    try {
        const response = await apiClient.get(`/athletes/${id}/results`);
        return response.data;
    } catch (error) {
        console.error('Error fetching athlete results:', error);
        throw error;
    }
};

export const fetchMeetParticipants = async (meetId) => {
    try {
        const response = await apiClient.get(`/meets/${meetId}/participants`);
        return response.data;
    } catch (error) {
        console.error('Error fetching participants:', error);
        throw error;
    }
};

export const fetchMeetCapacity = async (meetId) => {
    try {
        const response = await apiClient.get(`/meets/${meetId}/cap`);
        return response.data;
    } catch (error) {
        console.error('Error fetching meet capacity:', error);
        throw error;
    }
};

export const fetchAthletes = async () => {
    try {
        const response = await apiClient.get('/athletes');
        return response.data;
    } catch (error) {
        console.error('Error fetching athletes:', error);
        throw error;
    }
};

export const fetchAthleteById = async (id) => {
    try {
        const response = await apiClient.get(`/athletes/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching athlete by ID:', error);
        throw error;
    }
};

export const fetchClubs = async () => {
    try {
        const response = await apiClient.get('/clubs');
        return response.data;
    } catch (error) {
        console.error('Error fetching clubs:', error);
        throw error;
    }
};

export const fetchClubById = async (clubId) => {
    try {
        const response = await apiClient.get(`/clubs/${clubId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching club by ID:', error);
        throw error;
    }
};

export const fetchClubMembers = async (clubId) => {
    try {
        const response = await apiClient.get(`/clubs/${clubId}/members`);
        return response.data;
    } catch (error) {
        console.error('Error fetching club members:', error);
        throw error;
    }
};

export const fetchRankings = async (params) => {
    try {
        const response = await apiClient.get('/rankings', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching rankings:', error);
        throw error;
    }
};

export const fetchStandards = async () => {
    try {
        const response = await apiClient.get('/v1/standards');
        return response.data;
    } catch (error) {
        console.error('Error fetching standards:', error);
        throw error;
    }
};

export const registerParticipant = async (meetId, participantData) => {
    try {
        const response = await apiClient.post(`/v1/meets/${meetId}/participants`, participantData, {
            headers: AUTH_HEADER
        });
        return response.data;
    } catch (error) {
        console.error('Error registering participant:', error);
        throw error;
    }
};

export const validateMember = async (memberNumber) => {
    try {
        const response = await apiClient.post('/v1/meets/validate', { memberNumber }, {
            headers: AUTH_HEADER
        });
        return response.data;
    } catch (error) {
        console.error('Error validating member:', error);
        throw error;
    }
};

export const fetchReferees = async () => {
    try {
        const response = await apiClient.get('/v1/referees');
        return response.data;
    } catch (error) {
        console.error('Error fetching referees:', error);
        throw error;
    }
};

export const fetchAllDocuments = async () => {
    try {
        const response = await apiClient.get('/v1/documents');
        return response.data;
    } catch (error) {
        console.error('Error fetching documents:', error);
        throw error;
    }
};

export const fetchAllNews = async () => {
    try {
        const response = await apiClient.get('/v1/news');
        return response.data;
    } catch (error) {
        console.error('Error fetching news:', error);
        throw error;
    }
};

export const fetchNews = async (newsId) => {
    try {
        const response = await apiClient.get(`/v1/news/${newsId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching news:', error);
        throw error;
    }
};