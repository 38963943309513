import './rankings.css';
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

const Rankings = ({language}) => {

    const [labels] = useState({
        "rankings": {"french": "CLASSEMENTS", "english": "RANKINGS"},
        "all-time": {"french": "De tous les temps", "english": "All Time"},
        "all-sexes": {"french": "Tous les sexes", "english": "All Sexes"},
        "all-categories": {"french": "Toutes les divisions", "english": "All Divisions"},
        "all-types": {"french": "Tous les types", "english": "All Types"},
        
        "pl-long": {"french": "Dynamophilie", "english": "Powerlifting"},
        "bp-long": {"french": "Bench Press", "english": "Bench Press"},
        "cl-long": {"french": "Classique", "english": "Classic"},
        "eq-long": {"french": "Équipé", "english": "Equipped"},

        "pl": {"french": "D", "english": "P"},
        "bp": {"french": "B", "english": "B"},
        "cl": {"french": "C", "english": "C"},
        "eq": {"french": "E", "english": "E"},

        "men": {"french": "Hommes", "english": "Men"},
        "women": {"french": "Femmes", "english": "Women"},
        "search": {"french": "Rechercher", "english": "Search"},
        "rank": {"french": "Rang", "english": "Rank"},
        "name": {"french": "Nom", "english": "Name"},
        "age-class": {"french": "Classe d'âge", "english": "Age Class"},
        "weight": {"french": "Poids corporel", "english": "Weight"},
        "competition": {"french": "Compétition", "english": "Competition"},

        "o": {"french": "Open", "english": "Open"},
        "j": {"french": "Junior", "english": "Junior"},
        "sj": {"french": "Sub-Junior", "english": "Sub-Junior"},
        "m1": {"french": "Master I", "english": "Master I"},
        "m2": {"french": "Master II", "english": "Master II"},
        "m3": {"french": "Master III", "english": "Master III"},
        "m4": {"french": "Master IV", "english": "Master IV"}
    });

    const [currentResults, setCurrentResults] = useState({ });
    const [currentSex, setCurrentSex] = useState("all");
    const [meets, setMeets] = useState(0);
    const [years, setYears] = useState([]);

    function printYear(date) {
        return date.substring(0, 10);
    }

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/', {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
          }).then(function(myJson) {
              let meets = {};
              myJson.forEach(element => {
                meets[element.meetId] = {
                    "date": element.date || element.startDate,
                    "name": element.nom || element.eventName
                };
              });
              setMeets(meets);
          }).catch(function (e) {

        });

        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        setYears(range(currentYear, 2011, -1));
    }, []);

    const changeSelectOptionHandler = (event) => {
        setCurrentSex(event.target.value);
    };

    const onClick = () => {
        let wc = "all";
        if (currentSex === "m") {
            wc = document.getElementById("m-weight-class").value;
        } else if (currentSex === "w") {
            wc = document.getElementById("w-weight-class").value;
        }
        
        let url = 'https://sheltered-inlet-15640.herokuapp.com/api/rankings?year=' + document.getElementById("year").value + 
        '&type=' + document.getElementById("category").value + 
        '&division=' + document.getElementById("type").value + 
        '&gender=' + document.getElementById("sex").value + 
        '&ac=' + document.getElementById("age").value +
        '&wc=' + wc;

        fetch(url, {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
          }).then(function(myJson) {
              setCurrentResults(myJson);
          }).catch(function (e) {

        });
    }

    return (
        <div className="rankings-container">
            <h1>{labels['rankings'][language["language"]]}</h1>

            <div className="filter-container">
                <select name="year" id="year" className="filter">
                    <option value="all">{labels['all-time'][language["language"]]}</option>
                    {
                        years.map((x) => 
                        <option key={x}>{x}</option> )
                    }
                </select>
                <select name="sex" id="sex" className="filter" onChange={changeSelectOptionHandler}>
                    <option value="all">{labels['all-sexes'][language["language"]]}</option>
                    <option value="m">{labels['men'][language["language"]]}</option>
                    <option value="w">{labels['women'][language["language"]]}</option>
                </select>
                <select name="category" id="category" className="filter" defaultValue={'pl'}>
                    <option value="all">{labels['all-categories'][language["language"]]}</option>
                    <option value="pl">{labels['pl-long'][language["language"]]}</option>
                    <option value="bp">Bench Press</option>
                </select>
                <select name="age" id="age" className="filter">
                    <option value="all">Open</option>
                    <option value="j">Junior</option>
                    <option value="sj">Sub-Junior</option>
                    <option value="m1">Master I</option>
                    <option value="m2">Master II</option>
                    <option value="m3">Master III</option>
                    <option value="m4">Master IV</option>
                </select>
                <select name="type" id="type" className="filter" defaultValue={'cl'}>
                    <option value="all">{labels['all-types'][language["language"]]}</option>
                    <option value="cl">{labels['cl-long'][language["language"]]}</option>
                    <option value="eq">{labels['eq-long'][language["language"]]}</option>
                </select>

                {
                    (currentSex === "all") &&
                        <select name="all-weight-class" id="all-weight-class" className="filter">
                            <option value="all">Toutes les classes de poids</option>
                        </select>
                }

                {
                    (currentSex === "m") &&
                        <select name="m-weight-class" id="m-weight-class" className="filter">
                            <option value="all">Toutes les classes de poids</option>
                            <option value="%2B120kg">+120kg</option>
                            <option value="-120kg">-120kg</option>
                            <option value="-105kg">-105kg</option>
                            <option value="-93kg">-93kg</option>
                            <option value="-83kg">-83kg</option>
                            <option value="-74kg">-74kg</option>
                            <option value="-66kg">-66kg</option>
                            <option value="-59kg">-59kg</option>
                            <option value="-53kg">-53kg</option>
                        </select>
                }

                {
                    (currentSex === "w") &&
                        <select name="w-weight-class" id="w-weight-class" className="filter">
                            <option value="all">Toutes les classes de poids</option>
                            <option value="%2B84kg">+84kg</option>
                            <option value="-84kg">-84kg</option>
                            <option value="-76kg">-76kg</option>
                            <option value="-69kg">-69kg</option>
                            <option value="-63kg">-63kg</option>
                            <option value="-57kg">-57kg</option>
                            <option value="-52kg">-52kg</option>
                            <option value="-47kg">-47kg</option>
                            <option value="-43kg">-43kg</option>
                        </select>
                }

                <div className="search">
                    <button onClick={onClick}>{labels['search'][language["language"]]}</button>
                </div>
            </div>

            <div className="table-container">
                <div className="table-wrapper">
                    <table>
                        <tbody>
                            <tr>
                                <th>{labels['rank'][language["language"]]}</th>
                                <th>Date</th>
                                <th>{labels['name'][language["language"]]}</th>
                                {/* <th>{labels['competition'][language["language"]]}</th> */}
                                {/* <th>Division</th> */}
                                {/* <th>{labels['age-class'][language["language"]]}</th> */}
                                <th>Type</th>
                                <th>{labels['weight'][language["language"]]}</th>
                                <th>Squat</th>
                                <th>Bench Press</th>
                                <th>Deadlift</th>
                                <th>Total</th>
                                <th>GL</th>
                            </tr>

                            {
                                (Object.keys(currentResults).length >= 1) &&
                                Object.keys(currentResults).map((id, index) => {
                                    return (
                                    <tr key={index+1}>
                                        <td key={index+2}>{index+1}</td>
                                        <td key={index+3}>{printYear(meets[currentResults[id]["meetId"]]["date"])}</td>
                                        <td key={index+4}><Link to={"/athletes/" + currentResults[id]["athleteId"]} key={index+2}>{currentResults[id]["name"]}</Link></td>
                                        {/* <td key={index+9} className="cut-name">{meets[currentResults[id]["meetId"]]["name"]}</td> */}
                                        <td key={index+5}>{
                                        labels[currentResults[id]["type"]][language["language"]] + "-" +
                                        labels[currentResults[id]["division"]][language["language"]] + "-" +
                                        currentResults[id]["ac"].toUpperCase()
                                        }</td>
                                        {/* <td key={index+6}>{labels[currentResults[id]["ac"]][language["language"]]}</td> */}
                                        {/* <td key={index+7}>{labels[currentResults[id]["division"]][language["language"]]}</td> */}
                                        <td key={index+8}>{currentResults[id]["bw"]}</td>
                                        <td key={index+10}>{currentResults[id]["squat"]}</td>
                                        <td key={index+11}>{currentResults[id]["bench"]}</td>
                                        <td key={index+12}>{currentResults[id]["deadlift"]}</td>
                                        <td key={index+13}>{currentResults[id]["total"]}</td>
                                        <td key={index+14}>{Math.round(currentResults[id]["gl"] * 100) / 100}</td>
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Rankings
