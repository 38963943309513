import React from 'react';
import NewsListItem from './NewsListItem';
import './newsList.css';

const NewsList = ({ newsData }) => {
  return (
    <div className="news-list-container">
      {newsData.map((newsItem, index) => (
        <NewsListItem key={index} {...newsItem} />
      ))}
    </div>
  );
};

export default NewsList;