import React from 'react';
import { Link } from 'react-router-dom';

const NewsListItem = ({ title, date, content, imagePath, newsId }) => {

  const imageUrl = imagePath ? `${process.env.PUBLIC_URL}/assets/${imagePath}` : null;

  return (
    <li>
      <Link to={`/news/${newsId}`}>
        {imageUrl && <img src={imageUrl} alt="Image representing the news" />}
        <h3>{title}</h3>
        <p>{date}</p>
      </Link>
    </li>
  );
};

export default NewsListItem;