import React from 'react';
import IndividualNews from './individualNews/IndividualNews';
import useFetch from '../../../hooks/useFetch';
import { fetchNews } from '../../../services/api';
import './individualNewsPage.css';

const IndividualNewsPage = ({language, newsId}) => {

  const { data: newsItem, loading, error } = useFetch(() => fetchNews(newsId), [newsId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="individual-news-page-container">
      {newsItem ? (
        <IndividualNews {...newsItem} />
      ) : (
        <p>News item not found.</p>
      )}
    </div>
  );
};

export default IndividualNewsPage;