import React from 'react';
import './individualNews.css';

const IndividualNews = ({ title, date, content, imagePath }) => {
  
  const imageUrl = imagePath ? `${process.env.PUBLIC_URL}/assets/${imagePath}` : null;

  return (
    <div className="individual-news-container">
      <h1>{title}</h1>
      <h4>{date}</h4>
      {imageUrl && <img src={imageUrl} alt="Image representing the news" />}
      <div className="content-container" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default IndividualNews;