import './aoty.css';

import jsr2011 from '../assets/aoty/2011-JSR.jpg'
import sp2011 from '../assets/aoty/2011-SP.jpg'

import jw2012 from '../assets/aoty/2012-JW.jpg'
import mh2012 from '../assets/aoty/2012-MH.jpg'

import fr2013 from '../assets/aoty/2013-FR.jpg'
import sp2013 from '../assets/aoty/2013-SP.jpg'

import jb2014 from '../assets/aoty/2014-JB.jpg'
import jsr2014 from '../assets/aoty/2014-JSR.jpg'

import jn2015 from '../assets/aoty/2015-JN.jpg'
import sp2015 from '../assets/aoty/2015-SP.jpg'

import jb2016 from '../assets/aoty/2016-JB.jpg'
import js2016 from '../assets/aoty/2016-JS.jpg'

import em2017 from '../assets/aoty/2017-EM.jpg'
import wv2017 from '../assets/aoty/2017-WV.jpg'

import bv2018 from '../assets/aoty/2018-BV.jpg'
import jm2018 from '../assets/aoty/2018-JM.JPG'

import app2019 from '../assets/aoty/2019-APP.jpg'
import mc2019 from '../assets/aoty/2019-MC.jpg'

import mg2020 from '../assets/aoty/2020-MG.jpg'
import mc2020 from '../assets/aoty/2020-MC.jpg'

import mg2021 from '../assets/aoty/2021-MG.jpg'
import mat2021 from '../assets/aoty/2021-MAT.jpg'

import aurelie2022 from '../assets/aoty/2022-aurelie.jpg'
import hugo2022 from '../assets/aoty/2022-hugo.jpg'

import aurelie2023 from '../assets/aoty/2023/aurelie-nguyen.jpg'
import chris2023 from '../assets/aoty/2023/christopher-acosta.jpg'
import janet2023 from '../assets/aoty/2023/janet-warne.jpg'
import kayla2023 from '../assets/aoty/2023/kayla-overchuk.jpg'
import pierre2023 from '../assets/aoty/2023/pierre-baillargeon.jpg'
import tuan2023 from '../assets/aoty/2023/tuan-tran.jpg'

import {useState} from 'react'

const AthleteOfTheYear = ({language}) => {

    const [labels] = useState({
        "aoty": {"french": "ATHLÈTE DE L'ANNÉE", "english": "ATHLETE OF THE YEAR"},

        "2021mg": {"french": "Marianne Groleau s’est démarquée par sa performance au championnat mondial 2021 qui lui a valu une deuxième place dans la catégorie 84kg et moins junior.", "english": "Marianne Groleau stood out for her performance at the 2021 world championships which earned her a second place in the -84kg junior category."},
        "2021md": {"french": "Mathieu Doré s’est hissé au sommet du classement de la FQD suite à sa performance au championnat provincial 2021 avec une formule de 106,68 IPF GL Points incluant des records canadiens au squat et au total.", "english": "Mathieu Doré rose to the top of the FQD rankings following his performance at the 2021 provincial championships with a formula of 106.68 IPF GL Points including Canadian records in the squat and in the total."},
        
        "2020mg": {"french": "Lors du championnat national, Marianne a remporté la première place dans la catégorie junior -84 kg en plus d’inscrire des nouveaux records provinciaux junior et open pour le total avec 460 kg. Pour couronner sa compétition, elle a également reçu le titre de meilleure athlète féminine junior de la compétition.", "english": "At the national championship, Marianne took first place in the junior -84 kg category in addition to setting new junior and open provincial records for the total at 460 kg. To crown her competition, she also received the title of best junior female athlete in the competition."},
        "2020mc": {"french": "Marc s’est mesuré aux meilleurs athlètes nationaux dans la catégorie Open -120 kg. Dans une chaude lutte pour le podium il a terminé en première position.", "english": "Marc competed against the top national athletes in the Open -120 kg category. In a heated fight for the podium he finished in first position."},
        "2019app1": {"french": "Championne provinciale 2019 (Junior -52 kg)", "english": "2019 Provincial champion (Junior -52 kg)"},
        "2019app2": {"french": "Championne nationale 2019 (Junior -52 kg)", "english": "2019 National Champion (Junior -52 kg)"},
        "2019app3": {"french": "Championne mondiale 2019 (Junior -52 kg)", "english": "2019 World Champion (Junior -52 kg)"},
        "2019app4": {"french": "Médaille d’argent au bench press au Championnat mondial 2019 (82,5 kg)", "english": "Silver medal in bench press at the 2019 World Championship (82.5 kg)"},
        "2019app5": {"french": "Record national au squat (137 kg) et au bench press (82,5 kg)", "english": "National record in squat (137 kg) and bench press (82.5 kg)"},
        "2019app6": {"french": "Records provinciaux junior (SBDT) et open (SBT)", "english": "Provincial junior (SBDT) and open (SBT) records"},

        "2019mc1": {"french": "Champion Provincial 2019 (Open 120kg)", "english": "Provincial Champion 2019 (Open 120kg)"},
        "2019mc2": {"french": "Record Mondial au deadlift (372,5 kg)", "english": "World record in deadlift (372.5 kg)"},
        "2019mc3": {"french": "Record provincial et national au deadlift (380 kg)", "english": "Provincial and national deadlift record (380 kg)"},
        "2019mc4": {"french": "Médaille d’argent au NAPF (Open 120 kg)", "english": "Silver medal at NAPF (Open 120 kg)"},

        "2018bv1": {"french": "Champion provincial, national et mondial 2018 (Master 3 -74 kg)", "english": "Provincial, national and world champion 2018 (Master 3 -74 kg)"},
        "2018bv2": {"french": "Record national au squat (202,5 kg)", "english": "National squat record (202.5 kg)"},

        "2018jm1": {"french": "Championne nationale 2018 (Junior -63 kg)", "english": "2018 National Champion (Junior -63 kg)"},
        "2018jm2": {"french": "Record national junior -72 kg au bench press (94 kg)", "english": "Junior national record -72 kg bench press (94 kg)"},
        "2018jm3": {"french": "Médaille d’or au bench press au Championnat du monde 2018 (92,5 kg)", "english": "Gold medal in bench press at the 2018 World Championship (92.5 kg)"},

        "2017em1": {"french": "Championne régionale et nationale 2017 (Junior -63 kg)", "english": "Regional and national champion 2017 (Junior -63 kg)"},

        "2017wv1": {"french": "Champion provincial et mondial 2017 (Sub-junior -74 kg)", "english": "Provincial and World Champion 2017 (Sub-junior -74 kg)"},
        "2017wv2": {"french": "Records provinciaux Sub-junior -74 kg (SBDT) et Junior -74 kg (SDT)", "english": "Provincial records Sub-junior -74 kg (SBDT) and Junior -74 kg (SDT)"},
        "2017wv3": {"french": "Record national au deadlift -74 kg (258 kg) et au total (605,5 kg)", "english": "National deadlift record -74 kg (258 kg) and overall (605.5 kg)"},

        "2016js1": {"french": "Champion provincial 2016 (Open -83 kg)", "english": "Provincial champion 2016 (Open -83 kg)"},
        "2016js2": {"french": "Vice-champion national 2017 (Open -83 kg)", "english": "National runner-up 2017 (Open -83 kg)"},
        "2016js3": {"french": "Record provincial au squat (262,5 kg) et au total (707,5 kg)", "english": "Provincial record in squat (262.5 kg) and in total (707.5 kg)"},

        "2016jb1": {"french": "Championne provinciale 2016 (Open -57 kg)", "english": "Provincial champion 2016 (Open -57 kg)"},
        "2016jb2": {"french": "Vice-championne nationale (Open -57 kg)", "english": "National vice-champion (Open -57 kg)"},
        "2016jb3": {"french": "Médaille de bronze au Championnat du monde 2017 (Open -57 kg)", "english": "Bronze medal at the 2017 World Championship (Open -57 kg)"},
        "2016jb4": {"french": "Record national au bench (103 kg) et au deadlift (180 kg)", "english": "National record in bench (103 kg) and deadlift (180 kg)"},
        "2016jb5": {"french": "Records provinciaux Open -57 kg (SBDT)", "english": "Provincial Open Records -57 kg (SBDT)"},
        "2016jb6": {"french": "Meilleur Wilks féminin au Québec (502 wilks)", "english": "Best female Wilks in Quebec (502 wilks)"},

        "2015sp1": {"french": "Championne nationale 2015 (Junior -57 kg)", "english": "2015 National Champion (Junior -57 kg)"},
        "2015sp2": {"french": "Championne du Commonwealth 2015 (Junior -63 kg)", "english": "2015 Commonwealth Champion (Junior -63 kg)"},
        "2015sp3": {"french": "Record national au squat Junior -57 kg (135 kg), au bench press (88,5 kg) et au total (371 kg)", "english": "National record in the Junior squat -57 kg (135 kg), in the bench press (88.5 kg) and in total (371 kg)"},
        "2015sp4": {"french": "Record provincial Junior -57 kg (SBT)", "english": "Junior Provincial Record -57 kg (SBT)"},
        "2015sp5": {"french": "Record provincial Junior -63 kg (SBDT)", "english": "Junior Provincial Record -63 kg (SBDT)"},

        "2015jn1": {"french": "Champion provincial, national et mondial (Sub-Junior -93 kg)", "english": "Provincial, national and world champion (Sub-Junior -93 kg)"},
        "2015jn2": {"french": "Record national Sub-Junior -93 kg au deadlift (290 kg) et au total (650 kg)", "english": "National Sub-Junior record -93 kg in deadlift (290 kg) and in total (650 kg)"},
        "2015jn3": {"french": "Record du monde Sub-Junior -93 kg au deadlift (297,5 kg)", "english": "Sub-Junior World Record -93 kg in deadlift (297.5 kg)"},
        "2015jn4": {"french": "Records provinciaux Sub-Junior -93 kg (SBDT)", "english": "Provincial records Sub-Junior -93 kg (SBDT)"},

        "2014js1": {"french": "Champion national équipé 2014 (Master 1 -66 kg)", "english": "National champion equipped 2014 (Master 1 -66 kg)"},
        "2014js2": {"french": "Record provincial Open et Master 1 -66 kg (SBDT)", "english": "Provincial Open and Master Record 1 -66 kg (SBDT)"},

        "2014jb1": {"french": "Championne nationale et nord-américaine 2014 (Open -57 kg)", "english": "2014 National and North American Champion (Open -57 kg)"},
        "2014jb2": {"french": "Record national au bench press Open -57 kg (95 kg) et au total (382,5 kg)", "english": "National Open bench press record -57 kg (95 kg) and in total (382.5 kg)"},
        "2014jb3": {"french": "Record provincial Open -57 kg (SBT)", "english": "Provincial Open record -57 kg (SBT)"},

        "2013fr1": {"french": "Champion national 2013 (Master 1 Équipé 120+)", "english": "National Champion 2013 (Master 1 Equipped 120+)"},
        "2013fr2": {"french": "Champion mondial 2013 (Master 1 Équipé 120+)", "english": "World Champion 2013 (Master 1 Equipped 120+)"},
        "2013fr3": {"french": "Record provincial Master 1 Équipé 120+ au bench press (282,5 kg)", "english": "Provincial Record Master 1 Equipped with 120+ bench press (282.5 kg)"},

        "2013sp1": {"french": "Championne nationale 2013 (Junior -57 kg Équipé)", "english": "2013 National Champion (Junior -57 kg Equipped)"},
        "2013sp2": {"french": "Médaille de bronze au Championnat du monde (Junior -57 kg Équipé)", "english": "Bronze medal at the World Championship (Junior -57 kg Equipped)"},
        "2013sp3": {"french": "Record provincial Junior -57 kg Classique et Équipé (SBDT)", "english": "Provincial Junior Record -57 kg Classic and Equipped (SBDT)"},
        "2013sp4": {"french": "Record national Junior -57 kg Équipé (SBT)", "english": "Junior national record -57 kg Equipped (SBT)"},

        "2012mh1": {"french": "Champion provincial et national 2012 (Junior -120 kg)", "english": "Provincial and national champion 2012 (Junior -120 kg)"},
        "2012mh2": {"french": "Record provincial Junior -120 kg (SBDT)", "english": "Junior Provincial Record -120 kg (SBDT)"},
        "2012mh3": {"french": "Record provincial Open -120 kg (SBT)", "english": "Provincial Open record -120 kg (SBT)"},
        
        "2012jw1": {"french": "Championne nationale et mondiale 2012 (Master 3 -57 kg Équipé)", "english": "National and World Champion 2012 (Master 3 -57 kg Equipped)"},

        "2011js1": {"french": "Champion national 2011 (Open -66 kg Équipé)", "english": "National Champion 2011 (Open -66 kg Equipped)"},
        "2011js2": {"french": "Record provincial Open -66 kg Équipé (SBDT)", "english": "Provincial Open Record -66 kg Equipped (SBDT)"},

        "2011sp1": {"french": "Championne nationale 2011 (Junior -57 kg Équipé)", "english": "National Champion 2011 (Junior -57 kg Equipped)"},
        "2011sp2": {"french": "Record provincial Junior -57 kg Équipé (SBDT)", "english": "Provincial Junior Record -57 kg Equipped (SBDT)"},

        "2022an": {"french": "Aurélie Nguyen s’est démarquée par sa performance aux Championnats mondiaux 2022 qui lui a valu le titre de championne du monde -47kg junior, ainsi qu'un record mondial au deadlift à 170,5 kg. Elle termine également l'année avec une longue liste d'accomplissement : meilleure formule de tous les temps FQD, 3e meilleure formule de tous les temps CPU, plusieurs records canadiens et encore plus...", "english": "Aurélie Nguyen stood out for her performance at the 2022 World Championships which earned her the title of junior world champion -47kg, as well as a world deadlift record at 170.5 kg. She also ends the year with a long list of accomplishments: best formula of all time FQD, 3rd best formula of all time CPU, several Canadian records and more..."},
        "2022hb": {"french": "Hugo Bouthiette s'est particulièrement démarqué lors des Championnats canadiens en remportant le prix de meilleur leveur homme Open avec une formule de 103,51 GL. Il décroche également le titre de champion national -83kg Open.", "english":"Hugo Bouthiette particularly stood out during the Canadian Championships by winning the prize for best Open male lifter with a formula of 103.51 GL. He also won the title of national champion -83kg Open."},

        "2023kayla": {"french": "Kayla Overchuk a dominé la catégorie des -76 kg junior avec une force impressionnante. En remportant le titre de championne nationale, elle a non seulement prouvé son talent, mais elle a également décroché une place convoitée pour représenter le Canada au championnat du monde sub-junior et junior en Roumanie. Kayla a conclu l'année avec plusieurs records canadiens, notamment au squat, au deadlift et au total.", "english": "Kayla Overchuk dominated the junior -76 kg category with impressive strength. By winning the national championship title, she not only proved her talent but also secured a coveted spot to represent Canada at the sub-junior and junior world championships in Romania. Kayla concluded the year with several Canadian records, including in the squat, deadlift, and total."},
        "2023aurelie": {"french": "Aurélie Nguyen, dans la catégorie open, a marqué l'année avec des performances exceptionnelles. Son titre de championne nationale lui a ouvert les portes du championnat du monde, où elle a décroché une remarquable 4e place. En outre, elle s'est positionnée au sommet du classement féminin 2023, avec un score impressionnant de 113,96, tout en détenant tous les records canadiens dans sa catégorie.", "english": "Aurélie Nguyen, in the open category, had an exceptional year. Her national championship title earned her a spot at the world championship, where she achieved a remarkable 4th place finish. Additionally, she topped the 2023 women's rankings with an impressive score of 113.96, while also holding all Canadian records in her category."},
        "2023janet": {"french": "Janet Warne, une athlète respectée sur la scène nationale, a continué à briller dans la catégorie -57 kg master 4. Elle a réalisé un squat de 87,5 kg, établissant un record mondial non officiel puisqu’il a été exécuté lors d’une compétition locale. Son parcours remarquable témoigne de sa passion intemporelle pour la dynamophilie. Janet continue de repousser ses propres limites, inspirant ainsi une génération d'athlètes à poursuivre leurs rêves, peu importe leur âge.", "english": "Janet Warne, a respected athlete on the national stage, continued to shine in the master 4 -57 kg category. She achieved a squat of 87.5 kg, setting an unofficial world record as it was performed during a local competition. Her remarkable journey is a testament to her timeless passion for powerlifting. Janet continues to push her own limits, inspiring a generation of athletes to pursue their dreams, regardless of age."},
        "2023tran": {"french": "Tuan Tran s'est démarqué grâce à sa performance au championnat du monde ou il a terminé en quatrième position et établissant un record au deadlift de 307,5 kg tant dans la catégorie junior que open, dans la catégorie -66 kg.", "english": "Tuan Tran distinguished himself with his performance at the world championship where he finished in fourth place and set a deadlift record of 307.5 kg in both the junior and open categories, in the -66 kg weight class."},
        "2023chris": {"french": "Christopher Acosta-Tapia a laissé sa marque en tant que champion national et leader du classement FQD avec un score impressionnant de 111,25. Il a également établi un record canadien au deadlift et au total.", "english": "Christopher Acosta-Tapia made his mark as the national champion and leader of the FQD rankings with an impressive score of 111.25. He also set a Canadian record in the deadlift and total."},
        "2023pierre": {"french": "Pierre Baillargeon a dominé sur toutes les scènes, remportant non seulement le titre de champion national, mais aussi celui de champion mondial. Il inscrit son nom dans l'histoire en établissant tous les records provinciaux, ainsi que les records canadiens au squat, au deadlift et au total dans sa catégorie des -105 kg master 4.", "english": "Pierre Baillargeon dominated all competitions, winning not only the national championship title but also the world championship title. He etched his name in history by setting all provincial records, as well as Canadian records in the squat, deadlift, and total in the -105 kg master 4 category."},

    })

    return (
        <div className="aoty-container">
            <h1>{labels['aoty'][language["language"]]}</h1>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2023</h2>
                        <h3>Open</h3>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Christopher Acosta-Tapia</h3>
                                <img src={chris2023} alt=""/>
                                <ul>
                                    <p>{labels['2023chris'][language["language"]]}</p>
                                </ul>
                            </div>
                             <div className="recipient">
                                <h3>Aurélie Nguyen</h3>
                                <img src={aurelie2023} alt=""/>
                                <ul>
                                    <p>{labels['2023aurelie'][language["language"]]}</p>
                                </ul>
                            </div>
                        </div>
                        <h3>Juniors</h3>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Tran Tuan</h3>
                                <img src={tuan2023} alt=""/>
                                <ul>
                                    <p>{labels['2023tran'][language["language"]]}</p>
                                </ul>
                            </div>
                             <div className="recipient">
                                <h3>Kayla Overchuk</h3>
                                <img src={kayla2023} alt=""/>
                                <ul>
                                    <p>{labels['2023kayla'][language["language"]]}</p>
                                </ul>
                            </div>
                        </div>
                        <h3>Masters</h3>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Pierre Baillargeon</h3>
                                <img src={pierre2023} alt=""/>
                                <ul>
                                    <p>{labels['2023pierre'][language["language"]]}</p>
                                </ul>
                            </div>
                             <div className="recipient">
                                <h3>Janet Warne</h3>
                                <img src={janet2023} alt=""/>
                                <ul>
                                    <p>{labels['2023janet'][language["language"]]}</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2022</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Hugo Bouthiette</h3>
                                <img src={hugo2022} alt=""/>
                                <ul>
                                    <p>{labels['2022hb'][language["language"]]}</p>
                                </ul>
                            </div>
                             <div className="recipient">
                                <h3>Aurélie Nguyen</h3>
                                <img src={aurelie2022} alt=""/>
                                <ul>
                                    <p>{labels['2022an'][language["language"]]}</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2021</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Mathieu Doré</h3>
                                <img src={mat2021} alt=""/>
                                <ul>
                                    <p>{labels['2021md'][language["language"]]}</p>
                                </ul>
                            </div>
                             <div className="recipient">
                                <h3>Marianne Groleau</h3>
                                <img src={mg2021} alt=""/>
                                <ul>
                                    <p>{labels['2021mg'][language["language"]]}</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2020</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Marc Cardinal</h3>
                                <img src={mc2020} alt=""/>
                                <ul>
                                    <p>{labels['2020mc'][language["language"]]}</p>
                                </ul>
                            </div>
                             <div className="recipient">
                                <h3>Marianne Groleau</h3>
                                <img src={mg2020} alt=""/>
                                <ul>
                                    <p>{labels['2020mg'][language["language"]]}</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2019</h2>
                        <div className="recipients">
                             <div className="recipient">
                                <h3>Marc Cardinal</h3>
                                <img src={mc2019} alt=""/>
                                <ul>
                                    <li>{labels['2019mc1'][language["language"]]}</li>
                                    <li>{labels['2019mc2'][language["language"]]}</li>
                                    <li>{labels['2019mc3'][language["language"]]}</li>
                                    <li>{labels['2019mc4'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Amélie Picher-Plante</h3>
                                <img src={app2019} alt=""/>
                                <ul>
                                    <li>{labels['2019app1'][language["language"]]}</li>
                                    <li>{labels['2019app2'][language["language"]]}</li>
                                    <li>{labels['2019app3'][language["language"]]}</li>
                                    <li>{labels['2019app4'][language["language"]]}</li>
                                    <li>{labels['2019app5'][language["language"]]}</li>
                                    <li>{labels['2019app6'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2018</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Bernard Vachon</h3>
                                <img src={bv2018} alt=""/>
                                <ul>
                                    <li>{labels['2018bv1'][language["language"]]}</li>
                                    <li>{labels['2018bv2'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Justine Maltais</h3>
                                <img src={jm2018} alt=""/>
                                <ul>
                                    <li>{labels['2018jm1'][language["language"]]}</li>
                                    <li>{labels['2018jm2'][language["language"]]}</li>
                                    <li>{labels['2018jm3'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2017</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>William Veilleux</h3>
                                <img src={wv2017} alt=""/>
                                <ul>
                                    <li>{labels['2017wv1'][language["language"]]}</li>
                                    <li>{labels['2017wv2'][language["language"]]}</li>
                                    <li>{labels['2017wv3'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Élodie Morin</h3>
                                <img src={em2017} alt=""/>
                                <ul>
                                    <li>{labels['2017em1'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2016</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Jonathan Simard</h3>
                                <img src={js2016} alt=""/>
                                <ul>
                                    <li>{labels['2016js1'][language["language"]]}</li>
                                    <li>{labels['2016js2'][language["language"]]}</li>
                                    <li>{labels['2016js3'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Jessica Benedetto</h3>
                                <img src={jb2016} alt=""/>
                                <ul>
                                    <li>{labels['2016jb1'][language["language"]]}</li>
                                    <li>{labels['2016jb2'][language["language"]]}</li>
                                    <li>{labels['2016jb3'][language["language"]]}</li>
                                    <li>{labels['2016jb4'][language["language"]]}</li>
                                    <li>{labels['2016jb5'][language["language"]]}</li>
                                    <li>{labels['2016jb6'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2015</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Jake Navarra</h3>
                                <img src={jn2015} alt=""/>
                                <ul>
                                    <li>{labels['2015jn1'][language["language"]]}</li>
                                    <li>{labels['2015jn2'][language["language"]]}</li>
                                    <li>{labels['2015jn3'][language["language"]]}</li>
                                    <li>{labels['2015jn4'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Savanah Porzuczek</h3>
                                <img src={sp2015} alt=""/>
                                <ul>
                                    <li>{labels['2015sp1'][language["language"]]}</li>
                                    <li>{labels['2015sp2'][language["language"]]}</li>
                                    <li>{labels['2015sp3'][language["language"]]}</li>
                                    <li>{labels['2015sp4'][language["language"]]}</li>
                                    <li>{labels['2015sp5'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2014</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Jean-Sébastien Rhéaume</h3>
                                <img src={jsr2014} alt=""/>
                                <ul>
                                    <li>{labels['2014js1'][language["language"]]}</li>
                                    <li>{labels['2014js2'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Jessica Benedetto</h3>
                                <img src={jb2014} alt=""/>
                                <ul>
                                    <li>{labels['2014jb1'][language["language"]]}</li>
                                    <li>{labels['2014jb2'][language["language"]]}</li>
                                    <li>{labels['2014jb3'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2013</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Francis Rousseau</h3>
                                <img src={fr2013} alt=""/>
                                <ul>
                                    <li>{labels['2013fr1'][language["language"]]}</li>
                                    <li>{labels['2013fr2'][language["language"]]}</li>
                                    <li>{labels['2013fr3'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Savanah Porzuczek</h3>
                                <img src={sp2013} alt=""/>
                                <ul>
                                    <li>{labels['2013sp1'][language["language"]]}</li>
                                    <li>{labels['2013sp2'][language["language"]]}</li>
                                    <li>{labels['2013sp3'][language["language"]]}</li>
                                    <li>{labels['2013sp4'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2012</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Marc Harvey</h3>
                                <img src={mh2012} alt=""/>
                                <ul>
                                    <li>{labels['2012mh1'][language["language"]]}</li>
                                    <li>{labels['2012mh2'][language["language"]]}</li>
                                    <li>{labels['2012mh3'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Janet Warne</h3>
                                <img src={jw2012} alt=""/>
                                <ul>
                                    <li>{labels['2012jw1'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="year-container">
                        <h2>2011</h2>
                        <div className="recipients">
                            <div className="recipient">
                                <h3>Jean-Sébastien Rhéaume</h3>
                                <img src={jsr2011} alt=""/>
                                <ul>
                                    <li>{labels['2011js1'][language["language"]]}</li>
                                    <li>{labels['2011js2'][language["language"]]}</li>
                                </ul>
                            </div>
                            <div className="recipient">
                                <h3>Savanah Porzuczek</h3>
                                <img src={sp2011} alt=""/>
                                <ul>
                                <li>{labels['2011sp1'][language["language"]]}</li>
                                    <li>{labels['2011sp2'][language["language"]]}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AthleteOfTheYear
