import './merchandise.css';
import {useRef, useState} from 'react'

import cnb from '../assets/merchandise/cn-b.png'
import cnblue from '../assets/merchandise/cn-blue.png'
import cn from '../assets/merchandise/cn.pdf'

import hb from '../assets/merchandise/h-b.png'
import hblue from '../assets/merchandise/h-blue.png'
import h from '../assets/merchandise/h.pdf'

import lsb from '../assets/merchandise/ls-b.png'
import ls from '../assets/merchandise/ls.pdf'

import tsblack from '../assets/merchandise/ts-blue.png'
import tsblue from '../assets/merchandise/ts-black.png'
import ts from '../assets/merchandise/ts.pdf'

const Merchandise = ({language}) => {

    const [labels] = useState({
        "info": {"french": "Informations", "english": "Information"},
        "items": {"french": "Items", "english": "Items"},
        "merchandise": {"french": "ARTICLES PROMOTIONNELS", "english": "MERCHANDISE"}
    })

    const info = useRef();
    const infoButton = useRef();

    const onClick = (button, div) => {
        if(div.current.className === "content-wrapper-on") {
            button.current.innerHTML = "&plus;";
            div.current.className = "content-wrapper-off";
        } else {
            button.current.innerHTML = "&minus;";
            div.current.className = "content-wrapper-on";;
        }
    };

    return (
        <div className="merchandise-container">
            <h1>{labels['merchandise'][language["language"]]}</h1>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(infoButton, info)}>
                    <h3>{labels['info'][language["language"]]}</h3>
                    <button ref={infoButton}>&#43;</button>
                </div>

                <div ref={info} className="content-wrapper-off">
                    <p>Pour toute commande, contacter Patricia Paradis directement: patparadis97@gmail.com</p>

                    <br />
                    <br />

                    <p>Voici les guides des tailles. Les mesures sont en pouces. Les tailles sont unisexes et standards.</p>

                    <ul>
                        <li><a key={4} href={ts} download>T-shirt</a></li>
                        <li><a key={1} href={cn} download>Crewneck</a></li>
                        <li><a key={2} href={h} download>Hoodie</a></li>
                        <li><a key={3} href={ls} download>Manche longue</a></li>
                    </ul>

                    <br />
                    <br />

                    <p>Voici le prix de chaque item qui inclut l'ajout du nom de famille de l'athlète sur l'épaule (pour le t-shirt) ou le poignet (pour les autres items).</p>
                    <ul>
                        <li>T-shirt: 37,00$</li>
                        <li>Manche longue: 40,00$</li>
                        <li>Crewneck: 45,00$</li>
                        <li>Hoodie: 50,00$</li>
                    </ul>

                    <br />
                    <br />

                    <p>La livraison est offerte partout au Québec pour 13$ (taux fixe). Les athlètes de Québec peuvent contacter Patricia directement pour ramasser leur commande en personne.</p>
                    
                    <br />
                    <br />
                    
                    <p>Voici les informations nécessaires pour chaque commande:</p>
                    <ul>
                        <li>Nom de l'item </li>
                        <li>Couleur (sauf pour le manche longue)</li>
                        <li>Taille</li>
                        <li>Nom à ajouter (si souhaité)</li>
                    </ul>

                    <br />
                    <br />

                    <p>Pour la livraison: adresse postale complète (ne pas oublier votre numéro d'appartement si applicable) et numéro de téléphone. </p>
                   
                    <br />
                    <br />
                    
                    <p>Le paiement peut s'effectuer par virement Interac au 418-928-2515 ou patparadis97@gmail.com.</p>
                    <br />
                    <br />
                    <p>La question est: pour quelle fédération? Réponse: FQD</p>
                </div>
            </div>

            <div className="bubble-container">
                <div className="top-header">
                    <h3>{labels['items'][language["language"]]}</h3>
                </div>

                <div className="content-wrapper-on">
                    <div className="item-container">
                        <div className="item">
                            <p>Crewneck: 45,00$</p>
                            <img src={cnblue} alt=""/>
                            <img src={cnb} alt=""/>
                        </div>

                        <div className="item">
                            <p>T-Shirt: 37,00$</p>
                            <img src={tsblack} alt=""/>
                            <img src={tsblue} alt=""/>
                        </div>

                        <div className="item">
                            <p>Hoodie: 50,00$</p>
                            <img src={hb} alt=""/>
                            <img src={hblue} alt=""/>
                        </div>

                        <div className="item">
                            <p>Manche longue: 40,00$</p>
                            <img src={lsb} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Merchandise
