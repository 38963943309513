import './complaint.css';
import {useLayoutEffect, useRef, useState} from 'react'

import plainte from '../assets/complaint/plainte.png'
import complaintImg from '../assets/complaint/complaint.png'

import politique from '../assets/complaint/POLITIQUE.pdf'

import global from '../assets/complaint/1-GLOBAL.pdf'
import globale from '../assets/complaint/1-GLOBALE.pdf'

import quifaituqoi from '../assets/complaint/2-QUIFAITQUOI.pdf'
import quifaituqoien from '../assets/complaint/2-QUIFAITQUOI-EN.pdf'

import solution from '../assets/complaint/2-SOLUTION-FR.pdf'
import solutionen from '../assets/complaint/2-SOLUTION-EN.pdf'

import administrateur from '../assets/complaint/3-ADMINISTRATEUR.pdf'
import administrator from '../assets/complaint/3-ADMINISTRATOR.pdf'

import coach from '../assets/complaint/3-COACH.pdf'
import entraineur from '../assets/complaint/3-ENTRAINEUR.pdf'

import joueur from '../assets/complaint/3-JOUEUR.pdf'
import player from '../assets/complaint/3-PLAYER.pdf'

import officiel from '../assets/complaint/3-OFFICIEL.pdf'
import official from '../assets/complaint/3-OFFICIAL.pdf'

import parents from '../assets/complaint/3-PARENTS.pdf'
import parentsen from '../assets/complaint/3-PARENTS-EN.pdf'

import summaryText from '../assets/complaint/4-SUMMARY.pdf'
import synthese from '../assets/complaint/4-SYNTHESE.pdf'

const Complaint = ({language}) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const politics = useRef();
    const politicsButton = useRef();

    const documents = useRef();
    const documentsButton = useRef();

    const conduct = useRef();
    const conductButton = useRef();

    const summary = useRef();
    const summaryButton = useRef();

    const complaint = useRef();
    const complaintButton = useRef();

    const onClick = (button, div) => {
        if(div.current.className === "content-wrapper-on") {
            button.current.innerHTML = "&plus;";
            div.current.className = "content-wrapper-off";
        } else {
            button.current.innerHTML = "&minus;";
            div.current.className = "content-wrapper-on";;
        }
    };

    const [labels] = useState({
        "content": {
            "french": [
                <div key={0} className="complaint-container">
                    <a target="_blank" rel="noopener noreferrer" href="https://app.alias-solution.com/contact/fr/ls38">
                        <img src={plainte} alt=""/>
                    </a>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(politicsButton, politics)}>
                            <h3>1. Politique de gestion des cas d'abus et de harcèlement</h3>
                            <button ref={politicsButton}>&#43;</button>
                        </div>

                        <div ref={politics} className="content-wrapper-off">
                            <p>La ministre déléguée à l'Éducation et ministre responsable de la Condition féminine, Mme Isabelle Charest, à annoncé le 20 novembre dernier la 
                                mise en place, par l'ensemble des fédérations sportives québécoises, d'une politique de gestion des cas d'abus et de harcèlement, qui devra inclure 
                                un officier indépendant des plaintes.</p>

                            <br/>
                            <a target="_blank" rel="noopener noreferrer" 
                            href="https://www.quebec.ca/nouvelles/actualites/details/la-ministre-isabelle-charest-annonce-des-mesures-importantes-pour-contrer-les-cas-dabus-et-de-harcel/">EN SAVOIR PLUS</a>
                            <br/>
                            <br/>

                            <p>C’est aujourd’hui, grâce au travail conjoint du gouvernement, des fédérations sportives et des diverses parties prenantes composant la communauté 
                                sportive québécoise, qu’une politique cadre en matière de protection de l’intégrité de la personne est mise en place dans l’ensemble des fédérations 
                                sportives du Québec.</p>
                        </div>
                    </div>

                    
                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(documentsButton, documents)}>
                            <h3>2. Documents de références</h3>
                            <button ref={documentsButton}>&#43;</button>
                        </div>

                        <div ref={documents} className="content-wrapper-off">
                            <ul>
                                <li><a key={1} href={politique} download>Politique, règles et procédures en matière de protection de l'intégrité</a></li>
                                <li><a key={2} href={globale} download>Fiche globale</a></li>
                                <li><a key={3} href={quifaituqoi} download>Qui fait quoi?</a></li>
                                <li><a key={4} href={solution} download>Solution</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(conductButton, conduct)}>
                            <h3>3. Code de conduite</h3>
                            <button ref={conductButton}>&#43;</button>
                        </div>

                        <div ref={conduct} className="content-wrapper-off">
                            <ul>
                                <li><a key={5} href={administrateur} download>Administrateur</a></li>
                                <li><a key={6} href={entraineur} download>Entraîneur</a></li>
                                <li><a key={9} href={joueur} download>Athlète</a></li>
                                <li><a key={7} href={officiel} download>Officiel</a></li>
                                <li><a key={8} href={parents} download>Parents</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(summaryButton, summary)}>
                            <h3>4. Traitement d'une plainte</h3>
                            <button ref={summaryButton}>&#43;</button>
                        </div>

                        <div ref={summary} className="content-wrapper-off">
                            <p>Le traitement de plaintes est assurer par un officier indépendant dans le but d'établir un climat de confiance pour que les victimes puissent dénoncer sans crainte de représailles. Dorénavant, le processus de dénonciation sera standardisé et équitable, et les plaintes seront analysées de manière objective et indépendante. </p>
                            <br />
                            <p>Pour en savoir plus sur le traitement de plainte : </p>
                            <ul>
                                <li><a key={10} href={synthese} download>Synthèse</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(complaintButton, complaint)}>
                            <h3>5. Pour porter plainte</h3>
                            <button ref={complaintButton}>&#43;</button>
                        </div>

                        <div ref={complaint} className="content-wrapper-off">
                        <p><b>Quand porter plainte ?</b></p>
                        <br />
                        <p>Porte plainte dès qu’il y a des indices raisonnables de croire qu’un abus, du harcèlement, de la négligence ou de la violence a été commis dans un contexte sportif. </p>
                        <br /><br /><br />
                        <p><b>Qui peut porter plainte à l’Officier des plaintes ?</b></p>
                        <br />
                        <p>Toute personne impliquée de près ou de loin dans un milieu sportif qui vit ou qui a été témoin, d’abus, de harcèlement, de négligence ou de violence (p. ex. parents, athlètes, bénévoles, entraineurs, administrateurs, arbitres, etc.).</p>
                        <br /><br /><br />
                        <p><b>Comment porter plainte?</b></p>
                        <p>Via la plateforme <a target="_blank" rel="noopener noreferrer" 
                            href="https://app.alias-solution.com/contact/fr/ls38">
                                JE PORTE PLAINTE </a> ou en contactant Sport’Aide au 1 833 211-AIDE (2433)</p>
                        </div>
                    </div>
                </div>
            ], 

            
            
            
            
            
            
            
            
            "english": [
                <div key={0} className="complaint-container">
                    <a target="_blank" rel="noopener noreferrer" href="https://app.alias-solution.com/contact/en/ls38">
                        <img src={complaintImg} alt=""/>
                    </a>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(politicsButton, politics)}>
                            <h3>1. Abuse and Harassment Case Management Policy</h3>
                            <button ref={politicsButton}>&#43;</button>
                        </div>

                        <div ref={politics} className="content-wrapper-off">
                            <p>The Minister for Education and Minister responsible for the Status of Women, Ms. Isabelle Charest, announced on November 20 the
                                establishment, by all Quebec sports federations, of a management policy for cases of abuse and harassment, which must include
                                an independent complaints officer.</p>

                            <br/>
                            <a target="_blank" rel="noopener noreferrer" 
                            href="https://www.quebec.ca/nouvelles/actualites/details/la-ministre-isabelle-charest-annonce-des-mesures-importantes-pour-contrer-les-cas-dabus-et-de-harcel/">EN SAVOIR PLUS</a>
                            <br/>
                            <br/>

                            <p>It is now, thanks to the joint work of the government, sports federations and the various stakeholders that make up the community
                                Quebec sport, that a framework policy for the protection of personal integrity is in place in all federations
                                sports in Quebec.</p>
                        </div>
                    </div>

                    
                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(documentsButton, documents)}>
                            <h3>2. Reference documents</h3>
                            <button ref={documentsButton}>&#43;</button>
                        </div>

                        <div ref={documents} className="content-wrapper-off">
                            <ul>
                                <li><a key={1} href={politique} download>Integrity protection policy, rules and procedures</a></li>
                                <li><a key={2} href={global} download>Global sheet</a></li>
                                <li><a key={3} href={quifaituqoien} download>Who does what?</a></li>
                                <li><a key={4} href={solutionen} download>Solution</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(conductButton, conduct)}>
                            <h3>3. Code of conduct</h3>
                            <button ref={conductButton}>&#43;</button>
                        </div>

                        <div ref={conduct} className="content-wrapper-off">
                            <ul>
                                <li><a key={5} href={administrator} download>Administrator</a></li>
                                <li><a key={6} href={coach} download>Coach</a></li>
                                <li><a key={9} href={player} download>Athlete</a></li>
                                <li><a key={7} href={official} download>Official</a></li>
                                <li><a key={8} href={parentsen} download>Parents</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(summaryButton, summary)}>
                            <h3>4. Handling a complaint</h3>
                            <button ref={summaryButton}>&#43;</button>
                        </div>

                        <div ref={summary} className="content-wrapper-off">
                            <p>Complaints are handled by an independent officer in order to establish a climate of trust so that victims can report without fear of reprisals. From now on, the whistleblower process will be standardized and fair, and complaints will be analyzed objectively and independently.</p>
                            <br />
                            <p>To find out more about complaint handling : </p>
                            <ul>
                                <li><a key={10} href={summaryText} download>Summary</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="bubble-container">
                        <div className="top-header" onClick={() => onClick(complaintButton, complaint)}>
                            <h3>5. To file a complaint</h3>
                            <button ref={complaintButton}>&#43;</button>
                        </div>

                        <div ref={complaint} className="content-wrapper-off">
                        <p><b>When to file a complaint?</b></p>
                        <br />
                        <p>File a complaint as soon as there are reasonable indications to believe that abuse, harassment, neglect or violence has been committed in a sporting context.</p>
                        <br /><br /><br />
                        <p><b>Who can complain to the Complaints Officer ?</b></p>
                        <br />
                        <p>Anyone directly or indirectly involved in a sporting environment who experiences or has witnessed abuse, harassment, neglect or violence (e.g. parents, athletes, volunteers, coaches, administrators, referees, etc.).</p>
                        <br /><br /><br />
                        <p><b>How to file a complaint?</b></p>
                        <p>Via the platform <a target="_blank" rel="noopener noreferrer" 
                            href="https://app.alias-solution.com/contact/en/ls38">
                                I FILE A COMPLAINT </a> or by contacting Sport'Aide at 1 833 211-AIDE (2433)</p>
                        </div>
                    </div>
                </div>
            ]}
    });
    
    return (
        <>
            {labels['content'][language["language"]]}
        </>
    );
}

export default Complaint
