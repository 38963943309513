import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { registerParticipant } from '../../../services/api';
import './styles/memberForm.css';

const MemberForm = ({ language, meetId }) => {

    const registrationContainer = useRef();
    const cardNumber = useRef();
    const cardContainerRef = useRef();
    const error1 = useRef();
    const error2 = useRef();
    const division = useRef();
    const type = useRef();
    const ageClasses = useRef();
    const weightClasses = useRef();
    const [card, setCard] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState("M");
    const [canClick, setCanClick] = useState(false);
    const [possibleAgeClasses, setPossibleAgeClasses] = useState([]);
    const [athleteId, setAthleteId] = useState(0);

    const labels = {
        "card": {
            "french": "Numéro de carte CPU",
            "english": "CPU card number"
        },
        "invalid": {
            "french": "NUMÉRO DE CARTE INVALIDE",
            "english": "INVALID CARD NUMBER"
        },
        "invalid-des": {
            "french": "veuillez contacter info@fqd-quebec.com si le numéro ne fonctionne pour plus d'un jour ouvrable",
            "english": "please contact info@fqd-quebec.com if the number does not work for more than one business day"
        },
        "continue": {
            "french": "CONTINUER",
            "english": "CONTINUE"
        },
        "identification": {
            "french": "Identification de l'athlète",
            "english": "Athlete identification"
        },
        "first": {
            "french": "Prénom",
            "english": "First name"
        },
        "last": {
            "french": "Nom",
            "english": "Last name"
        },
        "registration": {
            "french": "Inscription",
            "english": "Registration"
        },
        "pl": {
            "french": "Dynamophilie",
            "english": "Powerlifting"
        },
        "ac": {
            "french": "Classe d'âge",
            "english": "Age class"
        },
        "cl": {
            "french": "Classique",
            "english": "Classic"
        },
        "eq": {
            "french": "Équipé",
            "english": "Equipped"
        },
        "wc": {
            "french": "Classe de poids",
            "english": "Weight class"
        },
        "d1": {
            "french": "La FQD se réserve le droit de procéder à des tests de dépistage de drogue, selon la procédure et les normes utilisées par la CPU et la IPF, c’est-à-dire en prélevant des échantillons d’urine. En considération de mon inscription à cette compétition, je m’engage à renoncer à tout recours légal contre les organisateurs, le site de l’événement, les fédérations concernées ainsi que toute personne liée de près ou de loin à cette compétition.",
            "english": "The QPF reserves the right to conduct drug testing, according to the procedure and standards used by the CPU and IPF, ie by taking urine samples. In consideration of my registration for this competition, I agree to waive any legal recourse against the organizers, the event site, the federations concerned as well as any person directly or indirectly linked to this competition."
        },
        "d2": {
            "french": "Par la présente, je confirme avoir lu les règlements de l’IPF et pris connaissance de la liste de tout l’équipement approuvé.",
            "english": "I hereby confirm that I have read the IPF Rules and are aware of the list of all approved equipment."
        },
        "d3": {
            "french": "J’ai lu et j’accepte les conditions",
            "english": "I have read and accept the conditions"
        },
        "final": {
            "french": "Finaliser l'inscription",
            "english": "Finalize registration"
        }
    };

    const register = async () => {
        const participantData = {
            athleteId: athleteId,
            isNovice: false,
            type: type.current.value,
            division: division.current.value,
            ageClass: ageClasses.current.value,
            possibleAgeClasses: possibleAgeClasses,
            gender: gender,
            weightClass: weightClasses.current.value
          };

          try {
            await registerParticipant(meetId, participantData);
          } catch (error) {
            console.log('ERREUR');
          }
    };


    const fetchMemberInfo = async (number) => {
        try {
            const response = await fetch(`https://sheltered-inlet-15640.herokuapp.com/api/v1/meets/validate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'E#na1RFaA1@jO!FU'
                },
                body: JSON.stringify({ memberNumber: number })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            error1.current.className = "error-shown";
            error2.current.className = "error-shown";
        }
    };

    const handleChange = async () => {
        let number = removeFirstCharIfNotNumber(cardNumber.current.value);
        const memberInformation = await fetchMemberInfo(number);
        if (memberInformation !== undefined) {
            registrationContainer.current.className = "registration-container-on";
            error1.current.className = "hidden";
            error2.current.className = "hidden";
            setCard(cardNumber.current.value);
            setFirstName(memberInformation.firstName);
            setLastName(memberInformation.lastName);
            setGender(memberInformation.gender);
            setAthleteId(memberInformation.athleteId);
    
            weightClasses.current.innerHTML = "";
            memberInformation.possibleWeightClasses.forEach((weightClass) => {
                let option = document.createElement("option");
                option.value = weightClass;
                option.text = weightClass;
                weightClasses.current.add(option);
            });

            ageClasses.current.innerHTML = "";
            let newPossibleAgeClasses = [];
            memberInformation.possibleAgeClasses.forEach((ageClass) => {
                let option = document.createElement("option");
                newPossibleAgeClasses.push(ageClass.className);
                option.value = ageClass.className;
                option.text = ageClass.className;
                ageClasses.current.add(option);
                setPossibleAgeClasses(newPossibleAgeClasses);
            });
        } else {
            registrationContainer.current.className = "registration-container-off";
        }
    };

    function removeFirstCharIfNotNumber(str) {
        if (isNaN(str.charAt(0))) {
            return str.substring(1);
        }
        return str;
    }

    return (
        <div className="member-registration-container">
            <div ref={cardContainerRef} className="hide card-container">
                <label>{labels['card'][language["language"]]}<input type="text" ref={cardNumber}/></label>
                <p ref={error1} className="hidden">-- {labels['invalid'][language["language"]]} -- </p>
                <p ref={error2} className="hidden">-- {labels['invalid-des'][language["language"]]} -- </p>
                <button onClick={() => handleChange(cardNumber)}>{labels['continue'][language["language"]]}</button>
            </div>

            <div ref={registrationContainer} className="registration-container-off">
                <div className="details-section">
                    <div className="line"></div>
                    <p>{labels['identification'][language["language"]]}</p>
                    <div className="line"></div>
                </div>

                <div className="details-container">
                    <div className="details">
                        <p className="detail-label">{labels['card'][language["language"]]}</p>
                        <p className="detail-text">{card}</p>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['first'][language["language"]]}</p>
                        <p className="detail-text">{firstName}</p>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['last'][language["language"]]}</p>
                        <p className="detail-text">{lastName}</p>
                    </div>
                </div>

                <div className="details-section">
                    <div className="line"></div>
                    <p>{labels['registration'][language["language"]]}</p>
                    <div className="line"></div>
                </div>

                <div className="details-container">
                    <div className="details">
                        <p className="detail-label">Type</p>
                        <div className="select-container">
                            <select ref={type} className="filter">
                                <option value="Dynamophilie">{labels['pl'][language["language"]]}</option>
                                <option value="Bench Press">Bench Press</option>
                            </select>
                        </div>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['ac'][language["language"]]}</p>
                        <div className="select-container">
                            <select ref={ageClasses} className="filter"></select>
                        </div>
                    </div>
                    <div className="details">
                        <p className="detail-label">Division</p>
                        <div className="select-container">
                            <select ref={division} className="filter">
                                <option value="Classique">{labels['cl'][language["language"]]}</option>
                                <option value="Équipé">{labels['eq'][language["language"]]}</option>
                            </select>
                        </div>
                    </div>
                    <div className="details">
                        <p className="detail-label">{labels['wc'][language["language"]]}</p>
                        <div className="select-container">
                            <select ref={weightClasses} className="filter"></select>
                        </div>
                    </div>
                </div>

                <div className="disclaimers-container">
                    <p className="disclaimers">{labels['d1'][language["language"]]}</p>
                    <p className="disclaimers">{labels['d2'][language["language"]]}</p>
                    <p className="disclaimers">{labels['d3'][language["language"]]} &emsp; <input type="checkbox" onClick={() => setCanClick(!canClick)}></input></p>
                </div>

                <Link to={"/meets/" + meetId + "/confirmation/"} onClick={() => register()} className={canClick ? "final" : "display-none"}>{labels['final'][language["language"]]}</Link>
            </div>
        </div>
    );
};

export default MemberForm;