import './documents.css';

import {useLayoutEffect, useRef, useState} from 'react'
import useFetch from '../../hooks/useFetch';
import { fetchAllDocuments } from '../../services/api';

const Documents = ({language}) => {
    const { data: documents, loading, error } = useFetch(fetchAllDocuments);

    const organism = useRef();
    const organismButton = useRef();
    const aga = useRef();
    const agaButton = useRef();
    const reports = useRef();
    const reportsButton = useRef();
    const onClick = (button, div) => {
        if(div.current.className === "content-wrapper-on") {
            button.current.innerHTML = "&plus;";
            div.current.className = "content-wrapper-off";
        } else {
            button.current.innerHTML = "&minus;";
            div.current.className = "content-wrapper-on";;
        }
    };

    const [labels] = useState({
        "aga": {"french": "Assemblées Générales Annuelles", "english":"Annual General Assemblies"},
        "documents": {"french": "DOCUMENTS", "english":"DOCUMENTS"},
        "organization": {"french": "Organisme", "english": "Organization"},
        "reports": {"french": "Rapport Annuels", "english": "Annual Reports"}
    })

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const sortDocuments = (docs) => {
        return docs.sort((a, b) => {
          const nameA = a.name[language["language"]].toUpperCase();
          const nameB = b.name[language["language"]].toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      };

    const renderDocuments = (category) => {
    const filteredDocuments = documents.filter((doc) => doc.category === category);
    const sortedDocuments = sortDocuments(filteredDocuments);

    return (
        <ul>
        {sortedDocuments.map((doc) => (
            <li key={doc._id}>
            <a href={`/assets/${doc.path}`} target="_blank" rel="noopener noreferrer">
                {doc.name[language["language"]]}
            </a>
            </li>
        ))}
        </ul>
    );
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="documents-container">
            <h1>{labels['documents'][language["language"]]}</h1>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(organismButton, organism)}>
                    <h3>{labels['organization'][language["language"]]}</h3>
                    <button ref={organismButton}>&#43;</button>
                </div>
                <div ref={organism} className="content-wrapper-off">
                    <div className="documents-list-container">
                        {renderDocuments('organization')}
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(agaButton, aga)}>
                    <h3>{labels['aga'][language["language"]]}</h3>
                    <button ref={agaButton}>&#43;</button>
                </div>
                <div ref={aga} className="content-wrapper-off">
                    <div className="documents-list-container">
                        {renderDocuments('minutes')}
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(reportsButton, reports)}>
                    <h3>{labels['reports'][language["language"]]}</h3>
                    <button ref={reportsButton}>&#43;</button>
                </div>
                <div ref={reports} className="content-wrapper-off">
                    <div className="documents-list-container">
                        {renderDocuments('annual-reports')}
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Documents
