import './club.css';
import {useState, useLayoutEffect, useEffect} from 'react'
import {Link} from 'react-router-dom'

import atlas from '../assets/clubs/atlas.jpg'
import bbrn from '../assets/clubs/bbrn.jpg'
import bemor from '../assets/clubs/bemor.png'
import gatineau from '../assets/clubs/gatineau.jpg'
import podium from '../assets/clubs/podium.jpg'
import black from '../assets/clubs/black.jpg'
import cde3l from '../assets/clubs/cde3l.jpg'
import forge from '../assets/clubs/forge.jpg'
import mofo from '../assets/clubs/mofo.jpg'
import mph from '../assets/clubs/mtlp.png'
import pr from '../assets/clubs/pr.jpg'
import rdl from '../assets/clubs/rdl.jpg'
import sherb from '../assets/clubs/sherb.jpg'
import ssmu from '../assets/clubs/ssmu.jpg'
import eastside from '../assets/clubs/eastside.jpg'
import sth from '../assets/clubs/sth.jpg'
import us from '../assets/clubs/us.jpg'
import vikings from '../assets/clubs/vikings.jpg'
import vicious from '../assets/clubs/vicious.jpg'
import walkyries from '../assets/clubs/walkyries.jpg'

import facebook from '../assets/facebook.png'
import maps from '../assets/maps.png'
import phone from '../assets/phone.png'

let logos = {
    "atlas": atlas,
    "bbrn": bbrn,
    "bemor": bemor,
    "podium": podium,
    "black": black,
    "cde3l": cde3l,
    "forge": forge,
    "gatineau": gatineau,
    "mofo": mofo,
    "mph": mph,
    "pr": pr,
    "rdl": rdl,
    "sherb": sherb,
    "eastside": eastside,
    "ssmu": ssmu,
    "sth": sth,
    "us": us,
    "vicious": vicious,
    "vikings": vikings,
    "walkyries": walkyries
}

const Club = ({language, clubId}) => {

    const [labels] = useState({
        "manager": {"french": "Responsable(s) : ", "english": "Manager(s) : "},
        "follow-us": {"french": "Suivez-nous: ", "english": "Follow Us"},
        "athletes": {"french": "LISTE DES ATHLÈTES", "english": "LIST OF ATHLETES"},
        "results": {"french": "MEILLEURS RÉSULTATS", "english": "BEST RESULTS"},
        "name": {"french": "Nom", "english": "Name"},
        "sex": {"french": "Sexe", "english": "Sex"},
        "m": {"french": "Homme", "english": "Man"},
        "w": {"french": "Femme", "english": "Woman"},
        "age": {"french": "Classe d'âge", "english": "Age Class"},
        "weight": {"french": "Poids", "english": "Weight"},
        "competition": {"french": "Compétition", "english": "Competition"},

        "pl": {"french": "D", "english": "P"},
        "bp": {"french": "B", "english": "B"},
        "cl": {"french": "C", "english": "C"},
        "eq": {"french": "E", "english": "E"},

        "o": "Open",
        "j": "Junior",
        "sj": "Sub-Junior",
        "m1": "Master I",
        "m2": "Master II",
        "m3": "Master III",
        "m4": "Master IV"
    });

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    function compare( a, b ) {
        return a.last.localeCompare(b.last);
    }

    const [currentClub, setCurrentClub] = useState({});
    const [competitions, setCompetitions] = useState({});
    const [members, setMembers] = useState([]);
    const [results, setResults] = useState([]);

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/clubs/' + clubId, {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
          }).then(function(myJson) {
              setCurrentClub(myJson);
          }).catch(function (e) {});

        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/', {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
          }).then(function(myJson) {
              let meets = {};
              myJson.forEach(element => {
                meets[element.meetId] = {
                    "date": element.date || element.startDate,
                    "name": element.nom || element.eventName
                };
              });
              setCompetitions(meets);
          }).catch(function (e) {});

        fetch('https://sheltered-inlet-15640.herokuapp.com/api/clubs/' + clubId + '/members', {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
            }).then(function(myJson) {
                myJson.sort(compare);
                setMembers(myJson);
            }).catch(function (e) {});

        fetch('https://sheltered-inlet-15640.herokuapp.com/api/clubs/' + clubId + '/results', {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                }
        }).then(function(response){
            if(response.ok) {
                return response.json();
            } else {
                throw Error('ERROR');
            }
            }).then(function(myJson) {
                setResults(myJson);
            }).catch(function (e) {});
    // eslint-disable-next-line
    }, []);

    return (
        <div className="club-container">
            <img src={logos[currentClub.logo]} alt=""/>
            <h1>{currentClub.name}</h1>
            <h3>{currentClub.region}</h3>
            <p className="taskperson"><b>{labels["manager"][language["language"]]}</b> {currentClub.manager}</p>
            <div className="contacts-container">
                <div className="contact">
                    <img src={phone} className="icons" alt=""/>
                    <p>{currentClub.phone}</p>
                </div>
                <div className="contact">
                    <img src={maps} className="icons" alt=""/>
                    <p>{currentClub.address} <br/> {currentClub.city} <br/> {currentClub.zip} </p>
                </div>
                <div className="contact">
                    <a target="_blank" rel="noopener noreferrer" href={currentClub.facebook}> <img src={facebook} className="icons" alt=""/> </a>
                    <p>{labels['follow-us'][language["language"]]}</p>
                </div>
            </div>

            <h2><b>{labels['athletes'][language["language"]]}</b></h2>
            <table className="athletes-table">
                <tbody>
                    <tr>
                        { 
                            members.map((athlete, index) => 
                                <td key={index}>
                                    <Link key={athlete.athleteId} to={"/athletes/".concat(athlete.athleteId)}>{athlete.last}, {athlete.first}</Link>
                                </td>
                            )
                        }
                    </tr>
                </tbody>
            </table>

            <h2>{labels['results'][language["language"]]}</h2>

            <div className="table-container">
                <div className="table-wrapper">
                    <table className="results">
                        <tbody>
                            <tr>
                                <th>Date</th>
                                <th className="first-col">{labels['name'][language["language"]]}</th>
                                <th>{labels['sex'][language["language"]]}</th>
                                {/* <th>{labels['competition'][language["language"]]}</th> */}
                                <th>Type</th>
                                {/* <th>Division</th> */}
                                {/* <th>{labels['age'][language["language"]]}</th> */}
                                <th>{labels['weight'][language["language"]]}</th>
                                <th>Squat</th>
                                <th>Bench Press</th>
                                <th>Deadlift</th>
                                <th>Total</th>
                                <th>GL</th>
                            </tr>

                            { 
                                Object.keys(competitions).length > 1 && results.map((athlete, index) => {
                                    return (                
                                        <tr key={index}>
                                            <td key={index+3}>{competitions[athlete["meetId"]]["date"].substring(0, 10)}</td>
                                            <td key={index+13}><Link to={"/athletes/" + athlete.athleteId}>{athlete["name"]}</Link></td>
                                            <td key={index+1}>{labels[athlete["gender"]][language["language"]]}</td>
                                            {/* <td key={index+2}>{competitions[athlete["meetId"]]["name"]}</td> */}
                                            <td key={index+4}>
                                                {
                                                    labels[athlete["type"]][language["language"]] + "-" +
                                                    labels[athlete["division"]][language["language"]] + "-" +
                                                    athlete["ac"].toUpperCase()
                                                }
                                            </td>
                                            <td key={index+7}>{athlete["bw"]}</td>
                                            <td key={index+8}>{athlete["squat"]}</td>
                                            <td key={index+9}>{athlete["bench"]}</td>
                                            <td key={index+10}>{athlete["deadlift"]}</td>
                                            <td key={index+11}>{athlete["total"]}</td>
                                            <td key={index+12}>{(athlete.gl === "-" ? "-" : Math.round(athlete.gl * 100) / 100)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Club
