import React, { useState, useEffect } from 'react';
import MemberOrNoviceSelection from './MemberOrNoviceSelection';
import MemberForm from './MemberForm';
import NoviceForm from './NoviceForm';
import MeetInfo from './MeetInfo';

const Registration = ({ language, meetId }) => {
    const [step, setStep] = useState('memberForm');
    const [novicesAllowed, setNovicesAllowed] = useState(false); // État pour stocker la valeur de novicesAllowed

    useEffect(() => {
        const fetchNovicesAllowed = async () => {
            try {
                const response = await fetch(`https://sheltered-inlet-15640.herokuapp.com/api/meets/${meetId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setNovicesAllowed(data[0].novicesAllowed);
            } catch (error) {
                console.error('Error fetching novicesAllowed:', error);
            }
        };

        fetchNovicesAllowed();
    }, [meetId]);

    const handleSelection = (selection) => {
        setStep(selection);
    };

    const handleNoviceSubmit = () => {
        setStep('confirmation');
    };

    return (
        <div className="registration-container">
            <MeetInfo meetId={meetId} language={language} />
            {novicesAllowed && <MemberOrNoviceSelection handleSelection={handleSelection} language={language} />}
            {step === 'memberForm' && <MemberForm language={language} meetId={meetId} />}
            {step === 'noviceForm' && <NoviceForm onSubmit={handleNoviceSubmit} language={language} meetId={meetId} />}
        </div>
    );
};

export default Registration;