import './organize-meet.css';

import {useState} from 'react'

const OrganizeMeet = ({language}) => {

    const [labels] = useState({
        "organize-meet": {"french": "ORGANISER UNE COMPÉTITION", "english": "ORGANIZE A COMPETITION"},
        "bars": {"french": "Barres", "english": "Bars"},
        "plates": {"french": "Plaques", "english": "Plates"}
    })

    return (
        <div className="organize-meet-container">
            <h1>{labels['organize-meet'][language["language"]]}</h1>

            <div className="bubble-container">
                <div className="content-wrapper-on">
                    <div className="eq-container">
                        <h2>Équipement approuvé</h2>
                        <div className="headers">
                            <p>Racks</p>
                            <p>{labels['bars'][language["language"]]}</p>
                            <p>{labels['plates'][language["language"]]}</p>
                        </div>
                        <div className="content">
                            <ul>
                                <li>ATX</li>
                                <li>Eleiko</li>
                                <li>ER Equipment</li>
                                <li>Hansu Power</li>
                                <li>MET-XT</li>
                                <li>Oni</li>
                                <li>Rogue</li>
                                <li>Zaoba Bull</li>
                            </ul>
                            <ul>
                                <li>ATX</li>
                                <li>Eleiko</li>
                                <li>Hansu Power</li>
                                <li>Ivanko</li>
                                <li>Maniak Fitness</li>
                                <li>MET-XT</li>
                                <li>Oni</li>
                                <li>Rogue</li>
                                <li>Strength Shop</li>
                                <li>Titex</li>
                                <li>Zaoba Bull</li>
                            </ul>
                            <ul>
                                <li>ATX</li>
                                <li>Eleiko</li>
                                <li>Hansu Power</li>
                                <li>Ivanko</li>
                                <li>Maniak Fitness</li>
                                <li>MET-XT</li>
                                <li>Oni</li>
                                <li>Rogue</li>
                                <li>Strength Shop</li>
                                <li>Strongarm calibrated plates</li>
                                <li>Titex</li>
                                <li>Zaoba Bull</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrganizeMeet
