import React, { useEffect, useState } from 'react';
import './styles/meetInfo.css';

const MeetInfo = ({ language, meetId }) => {

    const [meet, setMeet] = useState({"limit": "0"});
    const [labels] = useState({
        "registration": {
            "french": "Inscription",
            "english": "Registration"
        },
        "cl-eq": {
            "french": "Classique et équipé",
            "english": "Classic and equipped"
        },
        "charge": {
            "french": "Responsable",
            "english": "Person in charge"
        },
        "email": {
            "french": "Courriel",
            "english": "Email"
        },
        "weighin": {
            "french": "Heure de la pesée",
            "english": "Weigh In Time"
        },
        "comp": {
            "french": "Heure de la compétition",
            "english": "Competition Time"
        },
        "fees": {
            "french": "Frais d'inscription",
            "english": "Registration fees"
        },
        "fees-total": {
            "french": "$ (taxes incluses)",
            "english": "$ (taxes included)"
        },
        "deadline": {
            "french": "Date limite d'inscription",
            "english": "Registration deadline"
        },
        "limit": {
            "french": "Limite de participants",
            "english": "Limit of participants"
        }
    });

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/meets/' + meetId, {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                setMeet(myJson[0]);
            });
    // eslint-disable-next-line
    }, []);

    const formattedDate = meet.registrationEndDate && meet.registrationEndDate.trim() !== '' ? meet.registrationEndDate.substring(0, 10) : 'N/A';

    if(meetId < 220) {
        return (
            <div className="meet-info-container">
                <h2>{labels['registration'][language["language"]]}</h2>
                <h1>{meet.nom}</h1>
    
                <div className="info-container">
                    <div className="details">
                        <div className="detail">
                            <p className="detail-label">Division</p>
                            <p className="detail-text">{labels['cl-eq'][language["language"]]}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['charge'][language["language"]]}</p>
                            <p className="detail-text">{meet.charge}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['email'][language["language"]]}</p>
                            <p className="detail-text email">{meet.email}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['weighin'][language["language"]]}</p>
                            <p className="detail-text">{meet.weighin}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['comp'][language["language"]]}</p>
                            <p className="detail-text">{meet.comp}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['fees'][language["language"]]}</p>
                            <p className="detail-text">{meet.cost + labels['fees-total'][language["language"]]}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['deadline'][language["language"]]}</p>
                            <p className="detail-text">{meet.limit.substring(0, 10)}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['limit'][language["language"]]}</p>
                            <p className="detail-text">{meet.number}</p>
                        </div>
                    </div>
                </div>
    
                <div className="line" />
            </div>
        );
    } else {
        return (
            <div className="meet-info-container">
                <h2>{labels['registration'][language["language"]]}</h2>
                <h1>{meet.eventName}</h1>
    
                <div className="info-container">
                    <div className="details">
                        <div className="detail">
                            <p className="detail-label">Division</p>
                            <p className="detail-text">{labels['cl-eq'][language["language"]]}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['charge'][language["language"]]}</p>
                            <p className="detail-text">{meet.director}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['email'][language["language"]]}</p>
                            <p className="detail-text email">{meet.contactEmail}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['weighin'][language["language"]]}</p>
                            <p className="detail-text">{meet.weighInTime}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['comp'][language["language"]]}</p>
                            <p className="detail-text">{meet.liftingTime}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['fees'][language["language"]]}</p>
                            <p className="detail-text">{meet.cost + labels['fees-total'][language["language"]]}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['deadline'][language["language"]]}</p>
                            <p className="detail-text">{formattedDate}</p>
                        </div>
                        <div className="detail">
                            <p className="detail-label">{labels['limit'][language["language"]]}</p>
                            <p className="detail-text">{meet.capacity}</p>
                        </div>
                    </div>
                </div>
    
                <div className="line" />
            </div>
        );
    }
};

export default MeetInfo;