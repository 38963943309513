import './clubs.css';
import {useEffect, useState, useLayoutEffect, useRef} from 'react'
import {Link} from 'react-router-dom'

import atlas from '../assets/clubs/atlas.jpg'
import bbrn from '../assets/clubs/bbrn.jpg'
import bemor from '../assets/clubs/bemor.png'
import podium from '../assets/clubs/podium.jpg'
import black from '../assets/clubs/black.jpg'
import cde3l from '../assets/clubs/cde3l.jpg'
import forge from '../assets/clubs/forge.jpg'
import gatineau from '../assets/clubs/gatineau.jpg'
import mofo from '../assets/clubs/mofo.jpg'
import mph from '../assets/clubs/mtlp.png'
import pr from '../assets/clubs/pr.jpg'
import rdl from '../assets/clubs/rdl.jpg'
import sherb from '../assets/clubs/sherb.jpg'
import eastside from '../assets/clubs/eastside.jpg'
import ssmu from '../assets/clubs/ssmu.jpg'
import sth from '../assets/clubs/sth.jpg'
import us from '../assets/clubs/us.jpg'
import vikings from '../assets/clubs/vikings.jpg'
import vicious from '../assets/clubs/vicious.jpg'
import walkyries from '../assets/clubs/walkyries.jpg'

let logos = {
    "atlas": atlas,
    "bbrn": bbrn,
    "bemor": bemor,
    "podium": podium,
    "black": black,
    "cde3l": cde3l,
    "forge": forge,
    "gatineau": gatineau,
    "mofo": mofo,
    "mph": mph,
    "pr": pr,
    "rdl": rdl,
    "sherb": sherb,
    "eastside": eastside,
    "ssmu": ssmu,
    "sth": sth,
    "us": us,
    "vicious": vicious,
    "vikings": vikings,
    "walkyries": walkyries
}

const Clubs = ({language}) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    })

    const regionFilter = useRef();
    const [labels] = useState({
        "clubs": {"french": "LISTE DES CLUBS", "english": "LIST OF CLUBS"},
        "all-regions": {"french": "Toutes les régions", "english": "All Regions"}
    });
    const [clubs, setClubs] = useState([]);
    const [currentRegion, setCurrentRegion] = useState("all");

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/clubs/', {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        }).then(function(response){
            return response.json();
            }).then(function(myJson) {
                shuffle(myJson);
                setClubs(myJson);
            });
    }, []);

    function shuffle(array) {
        array.sort(() => Math.random() - 0.5);
    }

    const changeSelectOptionHandler = (event) => {
        setCurrentRegion(event.target.value);
    };

    return (
        <div className="clubs-container">
            <h1>{labels['clubs'][language["language"]]}</h1>
            <select ref={regionFilter} className="filter" onChange={changeSelectOptionHandler}>
                <option value="all">{labels['all-regions'][language["language"]]}</option>
                <option value="Bas-Saint-Laurent">01- Bas-Saint-Laurent</option>
                <option value="Saguenay/Lac-Saint-Jean">02- Saguenay/Lac-Saint-Jean</option>
                <option value="Capitale-Nationale">03- Capitale-Nationale</option>
                <option value="Mauricie">04- Mauricie</option>
                <option value="Estrie">05- Estrie</option>
                <option value="Montréal">06- Montréal</option>
                <option value="Outaouais">07- Outaouais</option>
                <option value="Abitibi-Témiscamingue">08- Abitibi-Témiscamingue</option>
                <option value="Côte-Nord">09- Côte-Nord</option>
                <option value="Nord-du-Québec">10- Nord-du-Québec</option>
                <option value="Gaspésie–Îles-de-la-Madeleine">11- Gaspésie–Îles-de-la-Madeleine</option>
                <option value="Chaudière-Appalaches">12- Chaudière-Appalaches</option>
                <option value="Laval">13- Laval</option>
                <option value="Lanaudière">14- Lanaudière</option>
                <option value="Laurentides">15- Laurentides</option>
                <option value="Montérégie">16- Montérégie</option>
                <option value="Centre-du-Québec">17- Centre-du-Québec</option>
            </select>
            <div className="links-container">
                {
                    clubs.filter(function (club) {
                        if(currentRegion !== "all") {
                            return club.region === currentRegion;
                        } else {
                            return club;
                        }
                      }).map((club, index) => {
                        return (
                            <div key={index} className="club">
                                <div key={index+1} className="img-container">
                                    <Link to={"/clubs/" + club.clubId}><img src={logos[club.logo]} alt=""/></Link>
                                </div>
                                <h3 key={index+2}>{club.name}</h3>
                                <p key={index+3}>{club.region}</p>
                                <p key={index+4}>{club.street}</p>
                                <p key={index+5}>{club.city}</p>
                                <p key={index+6}>{club.zip}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Clubs
