import React, { useLayoutEffect, useState, useEffect } from 'react';
import NewsList from './newsList/NewsList';
import useFetch from '../../../hooks/useFetch';
import { fetchAllNews } from '../../../services/api';
import './allNews.css';

const AllNews = () => {

  const { data: newsData, loading, error } = useFetch(fetchAllNews);
  const [language] = useState(JSON.parse(localStorage.getItem('language')) || { language: "french" });

  useLayoutEffect(() => {
      window.scrollTo(0, 0);
  });
  
  const [labels] = useState({
      "news": {"french": "NOUVELLES", "english":"NEWS"}
  })

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="news-page-container">
      <h1>{labels['news'][language["language"]]}</h1>
      <NewsList newsData={newsData} />
    </div>
  );
};

export default AllNews;