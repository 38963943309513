import './athletes.css';
import {useState, useEffect } from 'react'
import {Link} from 'react-router-dom'

const Athletes = ({language}) => {

    const [labels] = useState({
        "athletes": {"french": "LISTE DES ATHLÈTES", "english": "LIST OF ATHLETES"},
        "enter-name": {"french": "Entrer le nom...", "english": "Enter name..."}
    });
    
    const [originalList, setOriginalList] = useState(0);
    const [currentList, setCurrentList] = useState(0);

    function compare( a, b ) {
        return a.last.localeCompare(b.last);
    }

    useEffect(() => {
        fetch('https://sheltered-inlet-15640.herokuapp.com/api/athletes', {
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }).then(function(response){
            return response.json();
          }).then(function(myJson) {
              let newObject = {};
              myJson.sort(compare);
              myJson.forEach(athlete => {
                  let key = athlete.last.substring(0, 1).toUpperCase();
                  if (key in newObject) {
                      newObject[key].push(athlete);
                    } else {
                        newObject[key] = [athlete];
                    }
                });
                setOriginalList(newObject);
                setCurrentList(newObject);
            });
        }, []
    );

    const onKeyUp = () => {
        let filter = document.getElementById("myInput").value.toUpperCase();
        if (filter.value !== "") {
            let newList = {};
            for (let key in originalList) {
                let newArray = [];
                for (let athlete of originalList[key]){
                    if (athlete.last.toUpperCase().indexOf(filter) > -1 || athlete.first.toUpperCase().indexOf(filter) > -1) {
                        newArray.push(athlete);
                    }
                }
                if(newArray.length > 0) {
                    newList[key] = newArray;
                }
            }
            setCurrentList(newList);
        } else {
            setCurrentList(originalList);
        }
    }

    return (
        <div className="athletes-container">
            <h2>{labels['athletes'][language["language"]]}</h2>
            <div className="form-group">
                <input maxLength="50" type="text" id="myInput" onKeyUp={onKeyUp} placeholder={labels['enter-name'][language["language"]]}></input>
            </div>
            <div className="tables-container">
                {
                    Object.keys(currentList).length > 0 &&
                        Object.keys(currentList).map((key, index) => {
                        return (
                            <div key={index}>
                                <table key={index+1}>
                                    <tbody key={index+2}>
                                        <tr key={index+3}>
                                            <th key={index+4}>{key}</th>
                                        </tr>
                                        <tr key={index+5}>
                                            { 
                                                currentList[key].map((athlete) => 
                                                    <td key={athlete.athleteId+1000}>
                                                        <Link key={athlete.athleteId} to={"/athletes/".concat(athlete.athleteId)}>{athlete.last}, {athlete.first}</Link>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                {                                    
                                    index !== Object.keys(currentList).length -1 &&
                                    <div className="line" />
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Athletes
