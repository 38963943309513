import './dropdown.css';
import {Link} from 'react-router-dom'
import {useState} from 'react'

const Dropdown= ({language}) => {

    const [labels] = useState({
        "athletes": {"french": "LISTE DES ATHLÈTES", "english": "LIST OF ATHLETES"},
        "rankings": {"french": "CLASSEMENTS", "english": "RANKINGS"},
        "clubs": {"french": "LISTE DES CLUBS", "english": "LIST OF CLUBS"},
        "aoty": {"french": "ATHLÈTE DE L'ANNÉE", "english": "ATHLETE OF THE YEAR"},
        "results": {"french": "RÉSULTATS DE COMPÉTITION", "english": "COMPETITION RESULTS"},
        "meets-to-come": {"french": "COMPÉTITIONS À VENIR", "english": "UPCOMING COMPETITIONS"},
        "records": {"french": "RECORDS PROVINCIAUX", "english": "PROVINCIAL RECORDS"},
        "standards": {"french": "NORMES DE QUALIFICATION", "english": "QUALIFICATION STANDARDS"},
        "about": {"french": "À PROPOS", "english": "ABOUT"},
        "news": {"french": "NOUVELLES", "english": "NEWS"},
        "rules": {"french": "CONSTITUTION ET RÈGLEMENTS", "english": "CONSTITUTION AND BY-LAWS"},
        "referees": {"french": "ARBITRES", "english": "REFEREES"},
        "help": {"french": "GUIDE DE L'ATHLÈTE", "english": "ATHLETE GUIDE"},
        "anti-doping": {"french": "ANTIDOPAGE", "english": "ANTI-DOPING"},
        "organize-meet": {"french": "ORGANISER UNE COMPÉTITION", "english": "ORGANIZE A COMPETITION"},
        "athletes-menu": {"french": "ATHLÈTES", "english": "ATHLETES"},
        "competitions": {"french": "COMPÉTITIONS", "english": "COMPETITIONS"},
        "organization": {"french": "ORGANISATION", "english": "ORGANIZATION"},
        "resources": {"french": "RESSOURCES", "english": "RESOURCES"},
        "complaint": {"french": "JE PORTE PLAINTE", "english": "I FILE A COMPLAINT"},
        "merchandise": {"french": "ARTICLES PROMOTIONNELS", "english": "MERCHANDISE"}
    })

    return (
        <nav id="main-nav">
            <ul>
                <li>
                    <div className="menu-item first-item">
                        <Link to="/"> 
                            <p>{labels['athletes-menu'][language["language"]]}</p>
                            <span className="arrow">&#711;</span>
                        </Link>
                        <ul className="submenu">
                            <div className="line" />
                            <li>
                                <Link to="/athletes">{labels['athletes'][language["language"]]}</Link>
                                <Link to="/rankings">{labels['rankings'][language["language"]]}</Link>
                                <Link to="/clubs">{labels['clubs'][language["language"]]}</Link>
                                <Link to="/athlete-of-the-year">{labels['aoty'][language["language"]]}</Link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="menu-item second-item">
                        <Link to="/">{labels['competitions'][language["language"]]} <span className="arrow">&#711;</span></Link>
                        <ul className="submenu">
                            <div className="line" />
                            <li>
                                <Link to="/results">{labels['results'][language["language"]]}</Link>
                                <Link to="/meets">{labels['meets-to-come'][language["language"]]}</Link>
                                <Link to="/records">{labels['records'][language["language"]]}</Link>
                                <Link to="/standards">{labels['standards'][language["language"]]}</Link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="menu-item third-item">
                        <Link to="/">{labels['organization'][language["language"]]} <span className="arrow">&#711;</span></Link>
                        <ul className="submenu">
                            <div className="line"></div>
                            <li>
                                <Link to="/about">{labels['about'][language["language"]]}</Link>
                                <Link to="/news">{labels['news'][language["language"]]}</Link>
                                <Link to="/documents">DOCUMENTS</Link>
                                <Link to="/referees">{labels['referees'][language["language"]]}</Link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className="menu-item fourth-item">
                        <Link to="/">{labels['resources'][language["language"]]} <span className="arrow">&#711;</span></Link>
                        <ul className="submenu">
                            <div className="line"></div>
                            <li>
                                <Link to="/guide">{labels['help'][language["language"]]}</Link>
                                <Link to="/anti-doping">{labels['anti-doping'][language["language"]]}</Link>
                                <Link to="/organize-meet">{labels['organize-meet'][language["language"]]}</Link>
                                <Link to="/complaint">{labels['complaint'][language["language"]]}</Link>
                                {/* <Link to="/merchandise">{labels['merchandise'][language["language"]]}</Link> */}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Dropdown
