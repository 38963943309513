import './about.css';

import {useLayoutEffect, useRef, useState} from 'react'

import ame from '../assets/organization/ame.jpg'
import etienne from '../assets/organization/etienne.jpg'
import gab from '../assets/organization/gab.jpg'
import jay from '../assets/organization/jay.jpg'
import marianne from '../assets/organization/marianne.jpg'
import mathis from '../assets/organization/mathis-jolicoeur.jpg'
import nick from '../assets/organization/nick.jpg'
import sam from '../assets/organization/sam.jpg'

import etiennebio from '../assets/bio/etienne.pdf'
import gabbio from '../assets/bio/gabriel.pdf'
import jeremiebio from '../assets/bio/jeremie.pdf'
import mariannebio from '../assets/bio/marianne.pdf'
import samuelbio from '../assets/bio/samuel.pdf'

import mels from '../assets/organization/mels.jpg'

const About = ({language}) => {

    const [labels] = useState({
        "about": {"french": "À PROPOS", "english": "ABOUT"},
        "bio": {"french": "Notice Biographique", "english": "Biographical Notice"},
        "mission": {"french": "Mission, vision et valeurs", "english": "Our Mission"},
        "board": {"french": "Conseil d'administration", "english": "Board of Directors"},
        "pres-vice": {"french": "Président et vice-président", "english": "President and vice-president"},
        "president": {"french": "Président", "english": "President"},
        "vice-president": {"french": "Vice-président", "english": "Vice-president"},
        "vice-president-f": {"french": "Vice-présidente", "english": "Vice-president"},
        "treasurer": {"french": "Trésorier", "english": "Treasurer"},
        "secretary": {"french": "Secrétaire", "english": "Secretary"},
        "admin-f": {"french": "Administratrice", "english": "Administrators"},
        "admin": {"french": "Administrateur", "english": "Administrators"},
        "comms": {"french": "responsable aux communications", "english": "communications manager"},
        "management": {"french": "Direction générale", "english": "Executive management"},
        "gm": {"french": "Directeur général", "english": "General Manager"},
        "agm": {"french": "Directrice générale adjointe", "english": "Assistant General Manager"},
        "branding": {"french": "Responsable de l'image de marque", "english": "Branding Manager"},
        "communications": {"french": "Responsable des communications", "english": "Communications Manager"},
        "school": {"french": "Responsable sports-études", "english": "Sports-studies Manager"}
    })

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const board = useRef();
    const boardButton = useRef();
    const mission = useRef();
    const missionButton = useRef();
    const management = useRef();
    const managementButton = useRef();

    const onClick = (button, div) => {
        if(div.current.className === "content-wrapper-on") {
            button.current.innerHTML = "&plus;";
            div.current.className = "content-wrapper-off";
        } else {
            button.current.innerHTML = "&minus;";
            div.current.className = "content-wrapper-on";;
        }
    };

    return (
        <div className="about-container">
            <h1>{labels['about'][language["language"]]}</h1>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(missionButton, mission)}>
                    <h3>{labels['mission'][language["language"]]}</h3>
                    <button ref={missionButton}>&#43;</button>
                </div>
                <div ref={mission} className="content-wrapper-off">
                    <div className="mission-container">
                        <h3>Mission</h3>
                        <p>Promouvoir et développer la pratique de la dynamophilie au Québec</p>
                        <h3>Vision</h3>
                        <p>Être reconnu comme le leader québécois dans la pratique de la dynamophilie</p>
                        <h3>Valeurs</h3>
                        <div className="bottom-row-container">
                            <ul>
                                <li>Dépassement de soi</li>
                                <li>Intégrité</li>
                                <li>Esprit de communauté</li>
                                <li>Respect</li>
                                <li>Sport sain</li>
                            </ul>
                            <img className="mels" src={mels} alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(managementButton, management)}>
                    <h3>{labels['management'][language["language"]]}</h3>
                    <button ref={managementButton}>&#43;</button>
                </div>
                <div ref={management} className="content-wrapper-off">
                    <div className="people">
                        <div className="person">
                            <img src={nick} alt=""/>
                            <h4>Nicolas Déry</h4>
                            <p>{labels['gm'][language["language"]]}</p>
                            <p className="email">directeur-general@fqd-quebec.com</p>
                        </div>
                        <div className="person">
                            <img src={ame} alt=""/>
                            <h4>Amélie Picher-Plante</h4>
                            <p>{labels['agm'][language["language"]]}</p>
                            <p className="email">amelie.picher-plante@fqd-quebec.com</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bubble-container">
                <div className="top-header" onClick={() => onClick(boardButton, board)}>
                    <h3>{labels['board'][language["language"]]}</h3>
                    <button ref={boardButton}>&#43;</button>
                </div>
                <div ref={board} className="content-wrapper-off">
                    <div className="people">
                        <div className="person">
                            <h4>François Archambault</h4>
                            <p>{labels['vice-president'][language["language"]]}</p>
                        </div>
                        <div className="person">
                            <img src={jay} alt=""/>
                            <h4>Jérémie Borgia</h4>
                            <p>{labels['president'][language["language"]]}</p>
                            <a href={jeremiebio} download>{labels['bio'][language["language"]]}</a>
                            <p className="president">president@fqd-quebec.com</p>
                        </div>
                        <div className="person">
                            <img src={sam} alt=""/>
                            <h4>Samuel Boisjoly</h4>
                            <p>{labels['treasurer'][language["language"]]}</p>
                            <a href={samuelbio} download>{labels['bio'][language["language"]]}</a>
                            <p className="president">finances@fqd-quebec.com</p>
                        </div>
                        <div className="person">
                            <img src={etienne} alt=""/>
                            <h4>Étienne Bourdon</h4>
                            <p>{labels['secretary'][language["language"]]}</p>
                            <a href={etiennebio} download>{labels['bio'][language["language"]]}</a>
                        </div>
                        <div className="person">
                            <img src={marianne} alt=""/>
                            <h4>Marianne Groleau</h4>
                            <p>{labels['admin-f'][language["language"]]}</p>
                            <a href={mariannebio} download>{labels['bio'][language["language"]]}</a>
                        </div>
                        <div className="person">
                            <img src={mathis} alt=""/>
                            <h4>Mathis Jolicoeur</h4>
                            <p>{labels['admin'][language["language"]]}</p>
                        </div>
                        <div className="person">
                            <img src={gab} alt=""/>
                            <h4>Gabriel Aubé</h4>
                            <p>{labels['admin'][language["language"]]}</p>
                            <a href={gabbio} download>{labels['bio'][language["language"]]}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About
